import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GlobalVariable } from '../config/global';

@Injectable({
  providedIn: 'root'
})
export class FamilydayService {

  constructor(private http: HttpClient) { }

  getFamilyDayByBU(buId: string): Observable<any> {
    return this.http.get(
      buildFamilyDayByBUUrl(GlobalVariable.FAMILYDAY_READBYBU_EP, buId),
      {
        headers: this.getHeadersNA()
      }
    )
  }

  /* Create family member */
  postFamilyMember(submission: any): Observable<any> {
    return this.http.post(
      buildPOSTUrl(GlobalVariable.FAMILYMEMBER_CREATE_EP),
      submission,
      {
        headers: this.getHeadersNA()
      }
    )
  }

  /* Create family member (Request) */
  postFamilyMemberRequest(submission: any): Observable<any> {
    return this.http.post(
      buildPOSTUrl(GlobalVariable.FAMILYMEMBER_CREATE_MEMBERREQUEST_EP),
      submission,
      {
        headers: this.getHeadersNA()
      }
    )
  }

  /* Update family member */
  putFamilyMember(submission: any): Observable<any> {
    return this.http.post(
      buildPOSTUrl(GlobalVariable.FAMILYMEMBER_UPDATE_EP),
      submission,
      {
        headers: this.getHeadersNA()
      }
    )
  }

  /* Delete family member */
  deleteFamilyMember(id: string): Observable<any> {
    return this.http.get(buildFamilyMemberUrl(GlobalVariable.FAMILYMEMBER_DELETE_EP, id), {
      headers: this.getHeadersNA()
    })
  }

  getFamilyMembersByEmployee(id: string): Observable<any> {
    return this.http.get(
      buildFamilyMemberByEmployeeUrl(GlobalVariable.FAMILYMEMBER_READBYEMPLOYEE_EP, id),
      {
        headers: this.getHeadersNA()
      }
    )
  }

  postfamilyDayRequest(submission: any): Observable<any> {
    return this.http.post(
      buildPOSTUrl(GlobalVariable.FAMILYMEMBER_CREATEREQUEST_EP),
      submission,
      {
        headers: this.getHeadersNA()
      }
    );
  }

  private getHeadersNA() {
    // I included these headers because otherwise FireFox
    // will request text/html instead of application/json
    const headers = new HttpHeaders()
    headers.set("Accept", "application/json")
    return headers
  }
}

function buildPOSTUrl(type: string): string {
  let finalUrl = GlobalVariable.BASE_API_URL
  finalUrl += type
  return finalUrl
}

function buildFamilyDayByBUUrl(type: string, buId: string): string {
  let finalUrl = GlobalVariable.BASE_API_URL
  finalUrl += type
  finalUrl += "?buid=" + buId
  return finalUrl
}

function buildFamilyMemberByEmployeeUrl(type: string, id: string): string {
  let finalUrl = GlobalVariable.BASE_API_URL
  finalUrl += type
  finalUrl += "?id=" + id
  return finalUrl
}

function buildFamilyMemberUrl(type: string, dni: string): string {
  let finalUrl = GlobalVariable.BASE_API_URL
  finalUrl += type
  finalUrl += "?dni=" + dni
  return finalUrl
}
