import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable } from 'rxjs'
import { GlobalVariable } from '../config/global'

@Injectable({
  providedIn: 'root'
})
export class AllowanceService {
  constructor(private http: HttpClient) {}

  getEmployeeAllowances(dni: string, bu: string): Observable<any> {
    return this.http.get(
      buildEmployeeAllowanceUrl(GlobalVariable.EMPLOYEE_ALLOWANCE_EP, dni, bu),
      {
        headers: this.getHeadersNA()
      }
    )
  }

  private getHeadersNA() {
    // I included these headers because otherwise FireFox
    // will request text/html instead of application/json
    const headers = new HttpHeaders()
    headers.set('Accept', 'application/json')
    return headers
  }
}

function buildEmployeeAllowanceUrl(
  type: string,
  dni: string,
  bu: string
): string {
  let finalUrl = GlobalVariable.BASE_API_URL
  finalUrl += type
  finalUrl += '?dni=' + dni
  finalUrl += '&bu=' + bu
  return finalUrl
}
