import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { FormlyFieldConfig } from '@ngx-formly/core';
import * as SecureLS from 'secure-ls';
import { FamilydayService } from 'src/app/service/familyday.service';

@Component({
  selector: 'app-createobject-dialog',
  templateUrl: './createobject-dialog.component.html',
  styleUrls: ['./createobject-dialog.component.scss']
})
export class CreateobjectDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<CreateobjectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public object: any,
    private familyMemberService: FamilydayService,
    public snackBar: MatSnackBar
  ) { }
  ls: SecureLS
  userRole: string
  objectName: string
  form = new FormGroup({})
  model: any = {}
  fields: FormlyFieldConfig[] = []
  // requests
  isLoading = false
  errorMessage = ""
  // objects
  objectType = ""

  ngOnInit() {
    this.ls = new SecureLS({ encodingType: "aes" })
    this.userRole = this.ls.get("ur")
    this.objectType = this.object.type
    const object = this.object.object
    this.configObject(this.objectType, object)
  }

  configObject(objectType, object) {
    switch (objectType) {
      case "familymember":
        this.objectName = "Miembro de familia"
        this.model = object
        this.fields = [
          {
            key: "dni",
            type: "input",
            templateOptions: {
              type: 'number',
              label: "Identificación",
              placeholder: "Ingrese el número de identificación",
              required: true
            }
          },
          {
            key: "full_name",
            type: "input",
            templateOptions: {
              label: "Nombre completo",
              placeholder: "Ingrese el nombre completo",
              required: true
            }
          },
          {
            key: "birthday",
            type: "datepicker",
            templateOptions: {
              label: "Fecha de nacimiento",
              placeholder: "Ingrese la fecha de nacimiento",
              required: true
            }
          },
        ]
        break
      default:
        break

    }
  }

  submit(model) {
    if (this.form.valid) {
      this.isLoading = true
      let message = ""
      switch (this.objectType) {
        case "familymember":
          console.log("GUARDADO")
          console.log(model)
          /* ----------- PROBAR Y ELIMINAR COMENTARIOS --------------- */

          /* this.familyMemberService.postFamilyMember(model).subscribe(
            p => {
              message = p.message
              if (message !== "Error") {
                this.launchMessage("El objeto se ha creado correctamente")
                this.dialogRef.close(true)
              }
            },
            e => this.launchMessage(e),
            () => {
              this.isLoading = false
            }
          ) */
          break
        default:
          break
      }
    }
  }

  launchMessage(message: string) {
    this.errorMessage = ""
    this.isLoading = false
    const action = "OK"
    this.snackBar.open(message, action, {
      duration: 4000
    })
  }

  onNoClick(): void {
    this.dialogRef.close()
  }
}
