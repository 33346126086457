import { NgModule } from "@angular/core"
import { Routes, RouterModule } from "@angular/router"
import { AppComponent } from "./app.component"
import { LoginComponent } from "./view/login/login.component"
import { PasswordResetComponent } from "./view/password-reset/password-reset.component"
import { HomeComponent } from "./view/home/home.component"
import { ProfileComponent } from "./view/profile/profile.component"
import { AccountstatementComponent } from "./view/accountstatement/accountstatement.component"
import { EmploymentrecordComponent } from "./view/employmentrecord/employmentrecord.component"
import { DonationcertificateComponent } from "./view/donationcertificate/donationcertificate.component"
import { EarningscertificateComponent } from "./view/earningscertificate/earningscertificate.component"
import { VacationrequestComponent } from "./view/vacationrequest/vacationrequest.component"
import { SelectroleComponent } from "./view/selectrole/selectrole.component"
import { FamilydayrequestComponent } from "./view/familydayrequest/familydayrequest.component"

const routes: Routes = [
  { path: "", component: AppComponent },
  { path: "login", component: LoginComponent },
  { path: "passwordreset", component: PasswordResetComponent },
  { path: "selectrole", component: SelectroleComponent },
  { path: "home", component: HomeComponent },
  { path: "profile", component: ProfileComponent },
  { path: "accountstatement", component: AccountstatementComponent },
  { path: "employmentrecord", component: EmploymentrecordComponent },
  { path: "donationcertificate", component: DonationcertificateComponent },
  { path: "earningscertificate", component: EarningscertificateComponent },
  { path: "vacationrequest", component: VacationrequestComponent },
  { path: "familydayrequest", component: FamilydayrequestComponent }
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
