import { Component, OnInit } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { BnNgIdleService } from "bn-ng-idle"
import * as SecureLS from "secure-ls"
import { FormGroup, FormBuilder, FormControl, Validators } from "@angular/forms"
import { MatSnackBar } from "@angular/material"
import * as uuid from "uuid"
import { GlobalVariable } from "src/app/config/global"
import { UserService } from "src/app/service/user.service"
import { Role } from "src/app/model/role"
import { AuthkeyService } from "src/app/service/authkey.service"

@Component({
  selector: "app-selectrole",
  templateUrl: "./selectrole.component.html",
  styleUrls: ["./selectrole.component.scss"],
})
export class SelectroleComponent implements OnInit {
  isLoading = false
  errorMessage = ""
  AppName = GlobalVariable.APP_NAME
  roleForm: FormGroup
  ls: SecureLS
  // role information
  roles: Role[] = []
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private authKeyService: AuthkeyService
  ) {}

  ngOnInit() {
    this.ls = new SecureLS({ encodingType: "aes" })
    if (!this.validateSession()) {
      this.router.navigate(["/login"])
      return
    }
    this.initForms()
    this.getUserRoles()
  }

  validateSession(): boolean {
    if (localStorage.getItem("uid") === null) {
      return false
    } else {
      return true
    }
  }

  initForms() {
    this.roleForm = this.formBuilder.group({
      role: new FormControl("", [Validators.required]),
    })
  }

  getErrorMessage(component: string) {
    let errorMessage = ""
    switch (component) {
      case "role":
        errorMessage = this.roleForm.get("role").hasError("required")
          ? "campo requerido"
          : this.roleForm.get("role").hasError("role")
          ? "ingresa un correo válido"
          : ""
        break
    }
    return errorMessage
  }

  getUserRoles() {
    const userid = this.ls.get("uid")
    this.userService.getRoles(userid).subscribe(
      (p) => {
        this.roles = p.results !== undefined ? p.results : null
      },
      (e) => this.launchMessage(e),
      () => {
        if (this.roles) {
          let compareProps = ["id"]
          this.roles = this.getUniqueArray(this.roles, compareProps)
        }
      }
    )
  }

  getUniqueArray(arr = [], compareProps = []) {
    let modifiedArray = []
    if (compareProps.length === 0 && arr.length > 0)
      compareProps.push(...Object.keys(arr[0]))
    arr.map((item) => {
      if (modifiedArray.length === 0) {
        modifiedArray.push(item)
      } else {
        if (
          !modifiedArray.some((item2) =>
            compareProps.every(
              (eachProps) => item2[eachProps] === item[eachProps]
            )
          )
        ) {
          modifiedArray.push(item)
        }
      }
    })
    return modifiedArray
  }

  navigateTo(form: FormGroup) {
    const role = form.value.role
    if (role.id === "1") {
      this.router.navigate(["/home"])
    } else {
      this.generateAuthKeyAndNavigate(role)
    }
  }

  generateAuthKeyAndNavigate(role: Role) {
    const submission = this.buildJSONToPostAuthKey(role)
    this.authKeyService.postAuthKey(submission).subscribe(
      (p) => p.message,
      (e) => console.log(e),
      () => {
        this.isLoading = false
        switch (role.id) {
          case "4":
            window.location.href =
              GlobalVariable.BASE_SERVER_URL +
              "helpem-admin-fe/?navigationtype=login&authkey=" +
              submission.key
            break
          case "6":
            window.localStorage.clear()
            window.location.href =
              GlobalVariable.BASE_ALFOLI_SERVER_URL +
              "alfoli-admin-fe/login/?navigationtype=login&authkey=" +
              submission.key
            break
          case "7":
            window.localStorage.clear()
            window.location.href =
              GlobalVariable.BASE_ALFOLI_SERVER_URL +
              "alfoli-admin-fe/login/?navigationtype=login&authkey=" +
              submission.key
            break
          default:
            window.location.href =
              GlobalVariable.BASE_SERVER_URL +
              "helpem-admin-fe/?navigationtype=login&authkey=" +
              submission.key
            break
        }
      }
    )
  }

  /** this method builds the json using the data enter in the form and selected assets */
  buildJSONToPostAuthKey(role: Role): any {
    const employeeid = this.ls.get("uid")
    const x = {
      key: uuid.v4(),
      employee_id: employeeid,
      bu_code: role.bu_code,
      role_id: role.id,
    }
    return x
  }

  /** Launch message of the snackBar component */
  launchMessage(message: string) {
    this.errorMessage = ""
    this.isLoading = false
    const action = "OK"
    this.snackBar.open(message, action, {
      duration: 3000,
    })
  }
}
