import { Component, OnInit } from "@angular/core"
import { Router } from "@angular/router"
import * as SecureLS from "secure-ls"
import { HttpClient } from "@angular/common/http"
import { MatSnackBar, MatDatepicker } from "@angular/material"
import { FormControl, Validators } from "@angular/forms"
import { MediaObserver, MediaChange } from "@angular/flex-layout"
import { Subscription } from "rxjs"
import { GlobalVariable } from "src/app/config/global"
import { User } from "src/app/model/user"
import { Employee } from "src/app/model/employee"
import { EmployeeService } from "src/app/service/employee.service"
import { BusinessUnit } from "src/app/model/businessUnit"
import { BusinessunitService } from "src/app/service/businessunit.service"

@Component({
  selector: "app-earningscertificate",
  templateUrl: "./earningscertificate.component.html",
  styleUrls: ["./earningscertificate.component.scss"]
})
export class EarningscertificateComponent implements OnInit {
  AppName = ""
  currentScreenWidth: string = ""
  flexMediaWatcher: Subscription
  sidenavWidth = 4.2
  ls: SecureLS
  // user information
  user: User
  isLoading = false
  errorMessage = ""
  // employee information
  employee: Employee
  employees: Employee[]
  // Business Unit information
  selectBusinessUnit: FormControl
  businessUnits: BusinessUnit[] = []
  selectedBusinessUnit = <BusinessUnit>{
    id: ""
  }
  // date picker
  date: FormControl
  earningsCertificateExits = false

  constructor(
    private router: Router,
    private employeeService: EmployeeService,
    private businessunitService: BusinessunitService,
    public snackBar: MatSnackBar,
    private http: HttpClient,
    private mediaObserver: MediaObserver
  ) {
    this.flexMediaWatcher = mediaObserver.media$.subscribe(
      (change: MediaChange) => {
        if (change.mqAlias !== this.currentScreenWidth) {
          this.currentScreenWidth = change.mqAlias
        }
      }
    )
  }

  ngOnInit() {
    this.AppName = GlobalVariable.APP_NAME
    this.ls = new SecureLS({ encodingType: "aes" })
    if (!this.validateSession()) {
      this.router.navigate(["/login"])
      return
    }
    this.initForms()
    this.getEmployeeData()
  }

  ngOnDestroy(): void {
    this.flexMediaWatcher.unsubscribe()
  }

  increase() {
    this.sidenavWidth = 16
  }
  decrease() {
    this.sidenavWidth = 4.2
  }

  /** validate a valid session exists*/
  validateSession(): boolean {
    if (localStorage.getItem("uid") === null) {
      return false
    } else {
      return true
    }
  }

  /** function to init the forms */
  initForms() {
    this.selectBusinessUnit = new FormControl("", [Validators.required])
    this.date = new FormControl({ value: new Date(), disabled: false })
  }

  /** function to get the current logged in user, call the function to get projects or register the API Token */
  getEmployeeData() {
    this.isLoading = true
    this.employee = <Employee>{
      id: ""
    }
    const userid = this.ls.get("uid")
    this.employeeService.getEmployee(userid).subscribe(
      p => {
        this.employees = p.results !== undefined ? p.results : null
        this.employee = p.results !== undefined ? p.results[0] : null
      },
      e => this.launchMessage(e),
      () => {
        this.isLoading = false
        if (this.employee) {
          this.getBusinessUnitByEmployee()
        }
      }
    )
  }

  getBusinessUnitByEmployee() {
    this.isLoading = true
    this.employees.map(employee => {
      let bu: BusinessUnit
      this.businessunitService.getBusinessUnit(employee.bu_code).subscribe(
        p => (bu = p.results !== undefined ? p.results[0] : null),
        e => this.launchMessage(e),
        () => {
          this.isLoading = false
          if (bu) {
            this.businessUnits.push(bu)
          }
          this.selectedBusinessUnit = this.businessUnits.find(
            bu => bu.id == this.employee.bu_code
          )
          if (this.selectedBusinessUnit) {
            this.getEarningsCertificate()
          }
        }
      )
    })
  }

  /** Event handler when business unit select component changes */
  buSelectionChange(event: { value: BusinessUnit }) {
    this.selectedBusinessUnit = event.value
    this.employee = this.employees.find(e => e.bu_code === event.value.id)
    this.getEarningsCertificate()
  }

  /** Event handler when date picker component value changes */
  chosenYearHandler(normalizedMonth: Date, datepicker: MatDatepicker<Date>) {
    this.date.setValue(normalizedMonth)
    datepicker.close()
    if (this.employee) {
      this.getEarningsCertificate()
    }
  }

  getEarningsCertificate() {
    if (this.isLoading) {
      return
    }
    this.isLoading = true
    const year = this.date.value.getFullYear()
    const earningsCertificateURL =
      GlobalVariable.BASE_BLOBSTORAGE_URL +
      GlobalVariable.EARNINGSCERTIFICATE_BLOBCONTAINER_NAME +
      "/" +
      this.selectedBusinessUnit.id +
      "/" +
      year +
      "/" +
      this.employee.dni +
      ".pdf"
    this.downloadReport(earningsCertificateURL)
  }

  downloadReport(url: string) {
    this.http.get(url).subscribe(
      p => p,
      e => {
        // HANDLE file found
        if (e.status === 200) {
          this.earningsCertificateExits = true
          const a = document.createElement("a")
          document.body.appendChild(a)
          a.setAttribute("style", "display: none")
          a.href = url
          a.target = "_blank"
          a.click()
          window.URL.revokeObjectURL(url)
          a.remove() // remove the element
        }
        // HANDLE file not found
        if (e.status === 404) {
          this.earningsCertificateExits = false
        }
        this.isLoading = false
      }
    )
  }

  launchMessage(message: string) {
    this.errorMessage = ""
    this.isLoading = false
    const action = "OK"
    this.snackBar.open(message, action, {
      duration: 4000
    })
  }
}
