import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { GlobalVariable } from "../config/global";

@Injectable({
  providedIn: "root"
})
export class RequestService {
  constructor(private http: HttpClient) { }

  getRequests(employee_id: string, bu_code: string): Observable<any> {
    return this.http.get(
      buildRequestUrl(GlobalVariable.REQUEST_READ_EP, employee_id, bu_code),
      {
        headers: this.getHeadersNA()
      }
    );
  }

  postRequest(submission: any): Observable<any> {
    return this.http.post(
      buildPOSTRequestUrl(GlobalVariable.REQUEST_CREATE_EP),
      submission,
      {
        headers: this.getHeadersNA()
      }
    );
  }

  putRequest(submission: any): Observable<any> {
    return this.http.post(
      buildPOSTRequestUrl(GlobalVariable.FAMILYDAY_REQUEST_UPDATE_EP),
      submission,
      {
        headers: this.getHeadersNA()
      }
    )
  }

  private getHeadersNA() {
    // I included these headers because otherwise FireFox
    // will request text/html instead of application/json
    const headers = new HttpHeaders();
    headers.set("Accept", "application/json");
    return headers;
  }
}

function buildRequestUrl(type: string, employee_id: string, bu_code): string {
  let finalUrl = GlobalVariable.BASE_API_URL;
  finalUrl += type;
  finalUrl += "?employee_id=" + employee_id;
  finalUrl += "&bu_code=" + bu_code;
  return finalUrl;
}

function buildPOSTRequestUrl(type: string): string {
  let finalUrl = GlobalVariable.BASE_API_URL;
  finalUrl += type;
  return finalUrl;
}