import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import * as SecureLS from "secure-ls";
import { DatePipe } from "@angular/common";
import { MatSnackBar, MatDatepicker } from "@angular/material";
import { FormControl, Validators } from "@angular/forms";
import { MediaObserver, MediaChange } from "@angular/flex-layout";
import { Subscription } from "rxjs";
import * as moment from "moment";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { User } from "src/app/model/user";
import { Employee } from "src/app/model/employee";
import { GlobalVariable } from "src/app/config/global";
import { EmployeeService } from "src/app/service/employee.service";
import { BusinessUnit } from "src/app/model/businessUnit";
import { BusinessunitService } from "src/app/service/businessunit.service";
import { Donation } from "src/app/model/donation";
import { DonationService } from "src/app/service/donation.service";

@Component({
  selector: "app-donationcertificate",
  templateUrl: "./donationcertificate.component.html",
  styleUrls: ["./donationcertificate.component.scss"],
  providers: [DatePipe],
})
export class DonationcertificateComponent implements OnInit {
  AppName = "";
  currentScreenWidth: string = "";
  flexMediaWatcher: Subscription;
  sidenavWidth = 4.2;
  ls: SecureLS;
  // user information
  user: User;
  isLoading = false;
  errorMessage = "";
  // employee information
  employee: Employee;
  employees: Employee[];
  // Business Unit information
  selectBusinessUnit: FormControl;
  businessUnits: BusinessUnit[] = [];
  selectedBusinessUnit = <BusinessUnit>{
    id: "",
  };
  // date picker
  date: FormControl;
  // Donation information
  donation: Donation;
  // Report information
  letterTitle = "";
  letterOne = "";
  letterTwo = "";
  letterThree = "";
  letterFour = "";
  letterFive = "";
  letterSix = "";
  letterArticles = "";
  letterDate = "";
  letterBusinessUnit = "";

  constructor(
    private router: Router,
    private employeeService: EmployeeService,
    private businessunitService: BusinessunitService,
    private donationService: DonationService,
    public snackBar: MatSnackBar,
    private mediaObserver: MediaObserver,
    public datepipe: DatePipe
  ) {
    this.flexMediaWatcher = mediaObserver.media$.subscribe(
      (change: MediaChange) => {
        if (change.mqAlias !== this.currentScreenWidth) {
          this.currentScreenWidth = change.mqAlias;
        }
      }
    );
  }

  ngOnInit() {
    this.AppName = GlobalVariable.APP_NAME;
    this.ls = new SecureLS({ encodingType: "aes" });
    if (!this.validateSession()) {
      this.router.navigate(["/login"]);
      return;
    }
    this.initForms();
    this.getEmployeeData();
  }

  ngOnDestroy(): void {
    this.flexMediaWatcher.unsubscribe();
  }

  increase() {
    this.sidenavWidth = 16;
  }
  decrease() {
    this.sidenavWidth = 4.2;
  }

  /** validate a valid session exists*/
  validateSession(): boolean {
    if (localStorage.getItem("uid") === null) {
      return false;
    } else {
      return true;
    }
  }

  /** function to init the forms */
  initForms() {
    this.selectBusinessUnit = new FormControl("", [Validators.required]);
    this.date = new FormControl({ value: new Date(), disabled: false });
  }

  /** function to get the current logged in user, call the function to get projects or register the API Token */
  getEmployeeData() {
    this.isLoading = true;
    this.employee = <Employee>{
      id: "",
    };
    const userid = this.ls.get("uid");
    this.employeeService.getEmployee(userid).subscribe(
      (p) => {
        this.employees = p.results !== undefined ? p.results : null;
        this.employee = p.results !== undefined ? p.results[0] : null;
      },
      (e) => this.launchMessage(e),
      () => {
        this.isLoading = false;
        if (this.employee) {
          this.getBusinessUnitByEmployee();
        }
      }
    );
  }

  getBusinessUnitByEmployee() {
    this.isLoading = true;
    this.employees.map((employee) => {
      let bu: BusinessUnit;
      this.businessunitService.getBusinessUnit(employee.bu_code).subscribe(
        (p) => (bu = p.results !== undefined ? p.results[0] : null),
        (e) => this.launchMessage(e),
        () => {
          this.isLoading = false;
          if (bu) {
            this.businessUnits.push(bu);
          }
          this.selectedBusinessUnit = this.businessUnits.find(
            (bu) => bu.id == this.employee.bu_code
          );
          if (this.selectedBusinessUnit) {
            this.getDonation();
          }
        }
      );
    });
  }

  /** Event handler when business unit select component changes */
  buSelectionChange(event: { value: BusinessUnit }) {
    this.selectedBusinessUnit = event.value;
    this.employee = this.employees.find((e) => e.bu_code === event.value.id);
    this.getDonation();
  }

  /** Event handler when date picker component value changes */
  chosenYearHandler(normalizedMonth: Date, datepicker: MatDatepicker<Date>) {
    this.date.setValue(normalizedMonth);
    datepicker.close();
    if (this.employee) {
      this.getDonation();
    }
  }

  getDonation() {
    this.isLoading = true;
    const year = this.date.value.getFullYear();
    this.donationService
      .getDonationByUser(this.employee.dni, this.employee.bu_code, year)
      .subscribe(
        (p) => (this.donation = p.results !== undefined ? p.results[0] : null),
        (e) => this.launchMessage(e),
        () => {
          this.isLoading = false;
          if (this.donation) {
            this.buildCertificateContent();
          }
        }
      );
  }

  buildCertificateContent() {
    moment.locale("es");
    this.letterBusinessUnit =
      this.selectedBusinessUnit.id === "C11" ||
      this.selectedBusinessUnit.id === "U11"
        ? "IGLESIA ADVENTISTA DEL SEPTIMO DIA DE COLOMBIA "
        : "IGLESIA ADVENTISTA DEL SEPTIMO DIA – SECCION " +
          this.selectedBusinessUnit.name.toUpperCase();
    this.letterTitle =
      this.selectedBusinessUnit.id === "C11" ||
      this.selectedBusinessUnit.id === "U11"
        ? "EL CONTADOR GENERAL DE IGLESIA ADVENTISTA DEL SEPTIMO DIA DE COLOMBIA " +
          " CERTIFICA:"
        : "EL CONTADOR GENERAL DE IGLESIA ADVENTISTA DEL SEPTIMO DIA – SECCION " +
          this.selectedBusinessUnit.name.toUpperCase() +
          " CERTIFICA:";
    this.letterOne =
      this.selectedBusinessUnit.id === "C11" ||
      this.selectedBusinessUnit.id === "U11"
        ? "1. Que la IGLESIA ADVENTISTA DEL SEPTIMO DIA DE COLOMBIA" +
          ", es una entidad religiosa sin ánimo de lucro inscrita en el Ministerio del " +
          "Interior y Justicia con Personería Jurídica Especial según " +
          "resolución No. " +
          this.selectedBusinessUnit.resolution +
          ", NIT " +
          this.selectedBusinessUnit.national_code +
          " con domicilio en la ciudad de " +
          this.selectedBusinessUnit.location +
          "."
        : "1. Que la IGLESIA ADVENTISTA DEL SEPTIMO DIA – SECCION " +
          this.selectedBusinessUnit.name.toUpperCase() +
          ", es una entidad religiosa sin ánimo de lucro inscrita en el Ministerio del " +
          "Interior y Justicia con Personería Jurídica Especial según " +
          "resolución No. " +
          this.selectedBusinessUnit.resolution +
          ", NIT " +
          this.selectedBusinessUnit.national_code +
          " con domicilio en la ciudad de " +
          this.selectedBusinessUnit.location +
          ".";
    this.letterTwo =
      "2. Que esta entidad ha presentado las declaraciones de Ingresos y " +
      "Patrimonio en forma oportuna y sin interrupcion.";
    this.letterThree =
      "3. Que la entidad maneja mediante cuentas de ahorros " +
      "y corriente debidamente autorizados por la Superintendencia Bancaria " +
      "todos los ingresos por donaciones como los gastos propios de su actividad.";
    this.letterFour =
      "4. Que durante el año " +
      this.donation.year +
      " la entidad recibió de " +
      this.employee.name.toUpperCase().trim() +
      ", identificado con el documento No. " +
      this.employee.dni +
      " la suma de:";
    this.letterFive =
      "5. Que esta donación fue por concepto de diezmos y ofrendas";
    this.letterSix =
      "6. Que todos los ingresos recibidos como donaciones " +
      "son invertidos en el desarrollo de su objeto social dentro del terriorio " +
      "Nacional.";
    this.letterArticles =
      "Se expide para fines relacionados con los artículos " +
      "125, 125-1, 125-2, 125-3 del Estatuto Tributario.";
    this.letterDate =
      "En constancia se firma el " +
      moment().format("DD/MM/YYYY") +
      " en la ciudad de " +
      this.selectedBusinessUnit.location;
  }

  getCommaSeparatedTwoDecimalsNumber(number) {
    if (number < 0 && number > -0.001) {
      number = Math.abs(number);
    }
    const fixedNumber = Number.parseFloat(number).toFixed(2);
    return String(fixedNumber).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  getReport() {
    var doc = new jsPDF("p", "mm", "a4");
    const pageHeight =
      doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
    const pageWidth =
      doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
    const margin = 5;
    const rowMargin = 7;
    const lMargin = 20; //left margin in mm
    const rMargin = 30; //right margin in mm
    const pageCenter = pageWidth / 2;
    // From Javascript
    var img = new Image();
    img.src = "assets/icon_text_green_min.png";
    doc.addImage(img, "png", lMargin, 19, 55, 14);
    doc.setFontSize(8);
    doc.setFontType("normal");
    doc.setTextColor(62, 131, 145);
    doc.text(
      this.selectedBusinessUnit.name.toUpperCase(),
      lMargin,
      7.5 * margin,
      "left"
    );
    // bu information
    doc.text(
      "NIT: " + this.selectedBusinessUnit.national_code,
      lMargin * 7,
      4.5 * margin,
      "left"
    );
    doc.text(
      this.selectedBusinessUnit.address,
      lMargin * 7,
      5.1 * margin,
      "left"
    );
    doc.text(
      "Tel: " + this.selectedBusinessUnit.phone,
      lMargin * 7,
      5.7 * margin,
      "left"
    );
    doc.text(
      this.selectedBusinessUnit.location,
      lMargin * 7,
      6.3 * margin,
      "left"
    );
    doc.text(
      this.selectedBusinessUnit.website == null
        ? ""
        : this.selectedBusinessUnit.website,
      lMargin * 7,
      6.9 * margin,
      "left"
    );

    // right icon
    doc.setDrawColor(62, 131, 145);
    doc.line(pageWidth - 25, 15, pageWidth - 25, pageHeight - 15);
    var img = new Image();
    img.src = "assets/simbolo_green_min.png";
    doc.addImage(img, "png", pageWidth - 20, 19, 14, 14);
    doc.setFontSize(12);
    doc.setFontType("bold");
    doc.setTextColor(62, 131, 145);
    doc.text("CERTIFICADO DE DONACIÓN", pageCenter, 13 * margin, "center");
    doc.setFontSize(10);
    doc.setTextColor(0, 0, 0);
    var lines = doc.splitTextToSize(
      this.letterTitle,
      pageWidth - lMargin - rMargin
    );
    doc.text(lines, pageCenter, 16 * margin, "center");
    doc.setFontType("normal");
    lines = doc.splitTextToSize(this.letterOne, pageWidth - lMargin - rMargin);
    doc.text(lines, lMargin, 19 * margin, { maxWidth: 155, align: "justify" });
    lines = doc.splitTextToSize(this.letterTwo, pageWidth - lMargin - rMargin);
    doc.text(lines, lMargin, 23 * margin, { maxWidth: 155, align: "justify" });
    lines = doc.splitTextToSize(
      this.letterThree,
      pageWidth - lMargin - rMargin
    );
    doc.text(lines, lMargin, 25 * margin, { maxWidth: 155, align: "justify" });
    lines = doc.splitTextToSize(this.letterFour, pageWidth - lMargin - rMargin);
    doc.text(lines, lMargin, 28 * margin, { maxWidth: 155, align: "justify" });
    doc.setFontType("bold");
    lines = doc.splitTextToSize(
      this.getCommaSeparatedTwoDecimalsNumber(this.donation.amount),
      pageWidth - lMargin - rMargin
    );
    doc.text("$ " + lines, pageCenter, 31 * margin, "center");
    doc.setFontType("normal");
    lines = doc.splitTextToSize(this.letterFive, pageWidth - lMargin - rMargin);
    doc.text(lines, lMargin, 33 * margin, { maxWidth: 155, align: "justify" });
    lines = doc.splitTextToSize(this.letterSix, pageWidth - lMargin - rMargin);
    doc.text(lines, lMargin, 34 * margin, { maxWidth: 155, align: "justify" });
    lines = doc.splitTextToSize(
      this.letterArticles,
      pageWidth - lMargin - rMargin
    );
    doc.text(lines, lMargin, 37 * margin, { maxWidth: 155, align: "justify" });
    lines = doc.splitTextToSize(this.letterDate, pageWidth - lMargin - rMargin);
    doc.text(lines, lMargin, 39 * margin, { maxWidth: 155, align: "justify" });
    //signature
    var img = new Image();
    img.crossOrigin = "";
    img.src =
      GlobalVariable.BASE_BLOBSTORAGE_URL +
      GlobalVariable.SIGNATURE_BLOBCONTAINER_NAME +
      "/" +
      this.selectedBusinessUnit.id +
      "/" +
      "accountant.png";
    try {
      doc.addImage(img, "png", lMargin, pageHeight - 85, 65, 16);
    } catch (error) {
      console.log(error);
    }
    doc.setFontType("bold");
    doc.text(
      this.selectedBusinessUnit.accountant.toUpperCase(),
      lMargin,
      pageHeight - 68,
      "left"
    );
    doc.text("Contador Público", lMargin, pageHeight - 65, "left");
    doc.text(
      this.selectedBusinessUnit.accountant_code == null
        ? ""
        : "Tarjeta profesional: " + this.selectedBusinessUnit.accountant_code,
      lMargin,
      pageHeight - 62,
      "left"
    );
    doc.save("CERTIFICADO_DONACION_" + this.donation.year + ".pdf");
  }

  launchMessage(message: string) {
    this.errorMessage = "";
    this.isLoading = false;
    const action = "OK";
    this.snackBar.open(message, action, {
      duration: 4000,
    });
  }
}
