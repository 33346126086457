import { Component, OnInit, Output, EventEmitter } from "@angular/core"
import { Router } from "@angular/router"
import * as SecureLS from "secure-ls"
import { MatDialog } from "@angular/material"
import { GlobalVariable } from "src/app/config/global"
import { Employee } from "src/app/model/employee"
import { EmployeeService } from "src/app/service/employee.service"
import { ConfirmationDialogComponent } from "../confirmation-dialog/confirmation-dialog.component"

@Component({
  selector: "app-topmenu",
  templateUrl: "./topmenu.component.html",
  styleUrls: ["./topmenu.component.scss"]
})
export class TopmenuComponent implements OnInit {
  AppName = ""
  ls: SecureLS
  @Output() public sidenavToggle = new EventEmitter()
  // employee information
  employee: Employee

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private employeeService: EmployeeService
  ) {}

  ngOnInit() {
    this.AppName = GlobalVariable.APP_NAME
    this.ls = new SecureLS({ encodingType: "aes" })
    this.getEmployeeData()
  }

  public onToggleSidenav = () => {
    this.sidenavToggle.emit()
  }

  /** click event to settings */
  goToSettings() {}

  /** click event to log out */
  logOut() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "350px",
      data: "¿Estás seguro que quieres cerrar sesión?"
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // DO SOMETHING
        window.localStorage.clear()
        this.router.navigate(["/login"])
      }
    })
  }

  /** function to get the current logged in user, call the function to get projects or register the API Token */
  getEmployeeData() {
    this.employee = <Employee>{
      id: ""
    }
    const userid = this.ls.get("uid")
    this.employeeService.getEmployee(userid).subscribe(
      p => {
        this.employee = p.results !== undefined ? p.results[0] : null
      },
      e => console.log(e),
      () => {}
    )
  }
}
