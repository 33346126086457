import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import * as SecureLS from "secure-ls";
import { DatePipe } from "@angular/common";
import { MatSnackBar } from "@angular/material";
import { MediaObserver, MediaChange } from "@angular/flex-layout";
import { Subscription } from "rxjs";
import * as moment from "moment";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { User } from "src/app/model/user";
import { Employee } from "src/app/model/employee";
import { GlobalVariable } from "src/app/config/global";
import { EmployeeService } from "src/app/service/employee.service";
import { BusinessUnit } from "src/app/model/businessUnit";
import { BusinessunitService } from "src/app/service/businessunit.service";
import { Allowance } from "src/app/model/allowance";
import { AllowanceService } from "src/app/service/allowance.service";

@Component({
  selector: "app-employmentrecord",
  templateUrl: "./employmentrecord.component.html",
  styleUrls: ["./employmentrecord.component.scss"],
  providers: [DatePipe],
})
export class EmploymentrecordComponent implements OnInit {
  AppName = "";
  currentScreenWidth: string = "";
  flexMediaWatcher: Subscription;
  sidenavWidth = 4.2;
  ls: SecureLS;
  // user information
  user: User;
  isLoading = false;
  errorMessage = "";
  // employee information
  employee: Employee;
  // Business Unit information
  selectedBusinessUnit = <BusinessUnit>{
    id: "",
  };
  // employee allowances
  allowances: Allowance[] = [];
  // Certificate parts
  letterIntroduction = "";
  letterBusinessUnit = "";
  allowanceList = "";
  expeditionTime = "";

  constructor(
    private router: Router,
    private employeeService: EmployeeService,
    private businessunitService: BusinessunitService,
    private allowanceService: AllowanceService,
    public snackBar: MatSnackBar,
    private mediaObserver: MediaObserver,
    public datepipe: DatePipe
  ) {
    this.flexMediaWatcher = mediaObserver.media$.subscribe(
      (change: MediaChange) => {
        if (change.mqAlias !== this.currentScreenWidth) {
          this.currentScreenWidth = change.mqAlias;
        }
      }
    );
  }

  ngOnInit() {
    this.AppName = GlobalVariable.APP_NAME;
    this.ls = new SecureLS({ encodingType: "aes" });
    if (!this.validateSession()) {
      this.router.navigate(["/login"]);
      return;
    }
    this.getEmployeeData();
  }

  ngOnDestroy(): void {
    this.flexMediaWatcher.unsubscribe();
  }

  increase() {
    this.sidenavWidth = 16;
  }
  decrease() {
    this.sidenavWidth = 4.2;
  }

  /** validate a valid session exists*/
  validateSession(): boolean {
    if (localStorage.getItem("uid") === null) {
      return false;
    } else {
      return true;
    }
  }

  /** function to get the current logged in user, call the function to get projects or register the API Token */
  getEmployeeData() {
    this.isLoading = true;
    this.employee = <Employee>{
      id: "",
    };
    const userid = this.ls.get("uid");
    this.employeeService.getEmployee(userid).subscribe(
      (p) => {
        this.employee = p.results !== undefined ? p.results[0] : null;
      },
      (e) => this.launchMessage(e),
      () => {
        this.isLoading = false;
        if (this.employee) {
          this.getBusinessUnitByEmployee();
        }
      }
    );
  }

  getBusinessUnitByEmployee() {
    this.isLoading = true;
    let bu: BusinessUnit;
    this.businessunitService.getBusinessUnit(this.employee.bu_code).subscribe(
      (p) => (bu = p.results !== undefined ? p.results[0] : null),
      (e) => this.launchMessage(e),
      () => {
        this.isLoading = false;
        this.selectedBusinessUnit = bu;
        if (this.selectedBusinessUnit) {
          this.getAllowancesByEmployee();
        }
      }
    );
  }

  getAllowancesByEmployee() {
    this.isLoading = true;
    this.allowanceService
      .getEmployeeAllowances(this.employee.dni, this.employee.bu_code)
      .subscribe(
        (p) => {
          this.allowances = p.results !== undefined ? p.results : null;
        },
        (e) => this.launchMessage(e),
        () => {
          this.isLoading = false;
          if (this.employee) {
            this.buildCertificateContent();
          }
        }
      );
  }

  buildCertificateContent() {
    moment.locale("es");
    this.letterBusinessUnit =
      this.selectedBusinessUnit.id === "C11" ||
      this.selectedBusinessUnit.id === "U11"
        ? "IGLESIA ADVENTISTA DEL SEPTIMO DIA DE COLOMBIA "
        : "IGLESIA ADVENTISTA DEL SEPTIMO DIA – SECCION " +
          this.selectedBusinessUnit.name.toUpperCase();
    this.letterIntroduction =
      "Certifico que " +
      this.employee.name.toUpperCase().trim() +
      " con cédula de ciudadanía número " +
      this.employee.dni +
      " labora en la " +
      this.letterBusinessUnit +
      " desde el " +
      moment(this.employee.hired, "YYYY-MM-DD").format(
        "DD [de] MMMM [de] YYYY"
      ) +
      ". Actualmente tiene contrato a " +
      this.employee.contract_description +
      " y se desempeña como " +
      this.employee.job_description +
      ". Sus ingresos mensuales al momento son:";
    this.expeditionTime = moment().format("DD [de] MMMM [de] YYYY");
  }

  getCommaSeparatedTwoDecimalsNumber(number) {
    if (number < 0 && number > -0.001) {
      number = Math.abs(number);
    }
    const fixedNumber = Number.parseFloat(number).toFixed(2);
    return String(fixedNumber).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  getReport() {
    var doc = new jsPDF("p", "mm", "a4");
    doc.lineHeightProportion = 2;
    const allowances = this.allowances.map((allowance) => {
      return [
        allowance.dataitem_description,
        "$ " + this.getCommaSeparatedTwoDecimalsNumber(allowance.amount),
      ];
    });
    const pageHeight =
      doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
    const pageWidth =
      doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
    const margin = 5;
    const rowMargin = 7;
    const lMargin = 20; //left margin in mm
    const rMargin = 30; //right margin in mm
    const pageCenter = pageWidth / 2;
    // From Javascript
    var img = new Image();
    img.src = "assets/icon_text_green_min.png";
    doc.addImage(img, "png", lMargin, 19, 55, 14);
    doc.setFontSize(8);
    doc.setFontType("normal");
    doc.setTextColor(62, 131, 145);
    doc.text(
      this.selectedBusinessUnit.name.toUpperCase(),
      lMargin,
      7.5 * margin,
      "left"
    );
    // bu information
    doc.text(
      "NIT: " + this.selectedBusinessUnit.national_code,
      lMargin * 7,
      4.5 * margin,
      "left"
    );
    doc.text(
      this.selectedBusinessUnit.address,
      lMargin * 7,
      5.1 * margin,
      "left"
    );
    doc.text(
      "Tel: " + this.selectedBusinessUnit.phone,
      lMargin * 7,
      5.7 * margin,
      "left"
    );
    doc.text(
      this.selectedBusinessUnit.location,
      lMargin * 7,
      6.3 * margin,
      "left"
    );
    doc.text(
      this.selectedBusinessUnit.website == null
        ? ""
        : this.selectedBusinessUnit.website,
      lMargin * 7,
      6.9 * margin,
      "left"
    );
    doc.setDrawColor(62, 131, 145);
    doc.line(pageWidth - 25, 15, pageWidth - 25, pageHeight - 15);
    var img = new Image();
    img.src = "assets/simbolo_green_min.png";
    doc.addImage(img, "png", pageWidth - 20, 19, 14, 14);
    doc.setFontSize(12);
    doc.setFontType("bold");
    doc.setTextColor(62, 131, 145);
    doc.text("CERTIFICADO LABORAL", pageCenter, 13 * margin, "center");
    doc.setFontSize(10);
    doc.setTextColor(0, 0, 0);
    doc.setFontType("normal");
    var lines = doc.splitTextToSize(
      this.letterIntroduction,
      pageWidth - lMargin - rMargin
    );
    doc.text(lines, lMargin, 16 * margin, {
      align: "left",
      lineHeightFactor: 1.5,
    });
    //allowances
    doc.autoTable({
      styles: {
        fontSize: 10,
      },
      tableWidth: "wrap",
      margin: {
        right: rMargin,
        left: lMargin,
      },
      startY: 21 * margin,
      body: allowances,
      columnStyles: {
        1: { halign: "right" },
      },
      theme: "plain",
    });
    const afterTableMargin = 24 * margin + this.allowances.length * rowMargin;
    doc.text(
      "Se expide para fines de uso del interesado el " +
        moment().format("DD [de] MMMM [de] YYYY"),
      lMargin,
      afterTableMargin,
      "left"
    );
    doc.text("Atentamente,", lMargin, afterTableMargin + 2 * margin, "left");
    //signature
    var img = new Image();
    img.crossOrigin = "";
    img.src =
      GlobalVariable.BASE_BLOBSTORAGE_URL +
      GlobalVariable.SIGNATURE_BLOBCONTAINER_NAME +
      "/" +
      this.selectedBusinessUnit.id +
      "/" +
      "treasurer.png";
    try {
      doc.addImage(img, "png", lMargin, afterTableMargin + 6 * margin, 65, 16);
    } catch (error) {
      console.log(error);
    }
    doc.setFontType("bold");
    doc.text(
      this.selectedBusinessUnit.treasurer.toUpperCase(),
      lMargin,
      afterTableMargin + 9 * margin,
      "left"
    );
    doc.setFontType("normal");
    doc.text("Tesorero", lMargin, afterTableMargin + 10 * margin, "left");
    doc.text(
      this.letterBusinessUnit,
      lMargin,
      afterTableMargin + 11 * margin,
      "left"
    );
    doc.save("CERTIFICADO_LABORAL.pdf");
  }

  launchMessage(message: string) {
    this.errorMessage = "";
    this.isLoading = false;
    const action = "OK";
    this.snackBar.open(message, action, {
      duration: 4000,
    });
  }
}
