export const GlobalVariable = Object.freeze({
  APP_NAME: "HelpEm",
  // BASE_SERVER_URL: "http://localhost:4200/",
  // BASE_API_URL: 'http://localhost/helpem-api-rest/api/',
  // BASE_API_URL: 'https://sunpluscolombia.azurewebsites.net/api/',
  BASE_SERVER_URL: "https://empleados.iasd.org.co/",
  BASE_API_URL: "https://empleados.iasd.org.co/api/",
  BASE_BLOBSTORAGE_URL: "https://dwsda.blob.core.windows.net/",
  BASE_ALFOLI_SERVER_URL: "https://alfoliadventista.org/",
  EARNINGSCERTIFICATE_BLOBCONTAINER_NAME: "earningscertificate",
  SIGNATURE_BLOBCONTAINER_NAME: "signature",
  USER_VALIDATE_EP: "user/validate.php",
  USER_NUMBEROFROLES_EP: "user/readnumberofroles.php",
  USER_ROLES_EP: "user/readroles.php",
  USER_PASSWORDRESET_EP: "user/passwordreset.php",
  USER_UPDATE_EP: "user/update.php",
  USER_READONE_EP: "user/readone.php",
  AUTHKEY_CREATE_EP: "authKey/create.php",
  NEWS_BYBUSINESSUNIT_EP: "news/readbybusinessunit.php",
  EMPLOYEE_READONE_EP: "employee/readone.php",
  EMPLOYEE_TRANSACTION_BYUSER_EP: "employeeTransaction/readbyuser.php",
  BUSINESSUNIT_READONE_EP: "businessUnit/readone.php",
  EMPLOYEE_ALLOWANCE_EP: "allowance/readbyuserbusinesscode.php",
  EMPLOYEE_DONATION_EP: "donation/readbyuser.php",
  EMPLOYEE_VACATION_EP: "vacation/vacationdays.php",
  REQUEST_READ_EP: "request/readbyemployee.php",
  REQUEST_CREATE_EP: "request/create.php",
  ACCESSLOG_CREATE_EP: "accessLog/create.php",
  FAMILYDAY_READBYBU_EP: "familyDay/readbybu.php",
  FAMILYDAY_REQUEST_UPDATE_EP: "familyDay/updaterequest.php",
  FAMILYMEMBER_READBYEMPLOYEE_EP: "familyDay/readmemberbyemployee.php",
  FAMILYMEMBER_CREATE_EP: "familyDay/createmember.php",
  FAMILYMEMBER_UPDATE_EP: "familyDay/updatemember.php",
  FAMILYMEMBER_DELETE_EP: "familyDay/deletemember.php",
  FAMILYMEMBER_CREATEREQUEST_EP: "familyDay/createrequest.php",
  FAMILYMEMBER_CREATE_MEMBERREQUEST_EP: "familyDay/creatememberrequest.php"
})
