import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable } from 'rxjs'
import { GlobalVariable } from '../config/global'

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {
  constructor(private http: HttpClient) {}

  getEmployee(id: string): Observable<any> {
    return this.http.get(
      buildEmployeeUrl(GlobalVariable.EMPLOYEE_READONE_EP, id),
      {
        headers: this.getHeadersNA()
      }
    )
  }

  private getHeadersNA() {
    // I included these headers because otherwise FireFox
    // will request text/html instead of application/json
    const headers = new HttpHeaders()
    headers.set('Accept', 'application/json')
    return headers
  }
}

function buildEmployeeUrl(type: string, id: string): string {
  let finalUrl = GlobalVariable.BASE_API_URL
  finalUrl += type
  finalUrl += '?id=' + id
  return finalUrl
}
