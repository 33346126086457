import { Component, OnInit } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import * as SecureLS from "secure-ls"
import { FormGroup, FormBuilder, FormControl, Validators } from "@angular/forms"
import { MatSnackBar } from "@angular/material"
import { GlobalVariable } from "src/app/config/global"
import { User } from "src/app/model/user"
import { UserService } from "src/app/service/user.service"
import { AccesslogService } from "src/app/service/accesslog.service"

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"]
})
export class LoginComponent implements OnInit {
  isLoading = false
  errorMessage = ""
  loginForm: FormGroup
  AppName = GlobalVariable.APP_NAME
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private accessLogService: AccesslogService
  ) {}

  ngOnInit() {
    this.initForms()
  }

  initForms() {
    this.loginForm = this.formBuilder.group({
      email: new FormControl("", [Validators.required, Validators.email]),
      password: new FormControl("", [Validators.required])
    })
  }

  getErrorMessage(component: string) {
    let errorMessage = ""
    switch (component) {
      case "email":
        errorMessage = this.loginForm.get("email").hasError("required")
          ? "campo requerido"
          : this.loginForm.get("email").hasError("email")
          ? "ingresa un correo válido"
          : ""
        break
      case "password":
        errorMessage = this.loginForm.get("password").hasError("required")
          ? "campo requerido"
          : ""
        break
    }
    return errorMessage
  }

  validateCredentials(form: FormGroup) {
    let user: User
    this.userService
      .getValidateUser(form.value.email, form.value.password)
      .subscribe(
        p => {
          user = p.results !== undefined ? p.results[0] : null
        },
        e => console.log(e),
        () => {
          this.isLoading = false
          if (user) {
            this.createAccessLog(user)
            this.getNumberOfRoles(user)
          } else {
            this.launchMessage("Credenciales incorrectas. Intenta nuevamente.")
            this.loginForm.get("password").reset()
          }
        }
      )
  }

  createAccessLog(user: User) {
    const submission = this.buildJSONToPostAccessLog(user.id)
    this.accessLogService.postAccessLog(submission).subscribe(
      p => p.message,
      e => console.log(e),
      () => {
        this.isLoading = false
      }
    )
  }

  getNumberOfRoles(user: User) {
    let numberOfRoles = 0
    this.userService.getNumberOfRoles(user.id).subscribe(
      p => {
        numberOfRoles = p.results !== undefined ? p.results[0].count : 0
      },
      e => console.log(e),
      () => {
        this.isLoading = false
        this.setUser(user)
        if (numberOfRoles > 1) {
          this.router.navigate(["/selectrole"])
        } else {
          this.router.navigate(["/home"])
        }
      }
    )
  }

  setUser(user: User) {
    var ls = new SecureLS({ encodingType: "aes" })
    ls.set("uid", user.id)
    this.loginForm.reset()
  }

  /** this method builds the json using the data enter in the form and selected assets */
  buildJSONToPostAccessLog(employee_id: string): any {
    const now = new Date()
    const month = now.getMonth() + 1
    const x = {
      employee_id: employee_id,
      access_datetime:
        now.getFullYear() +
        "-" +
        month +
        "-" +
        now.getDate() +
        " " +
        now.getHours() +
        ":" +
        now.getMinutes() +
        ":" +
        now.getSeconds()
    }
    return x
  }

  /** Launch message of the snackBar component */
  launchMessage(message: string) {
    this.errorMessage = ""
    this.isLoading = false
    const action = "OK"
    this.snackBar.open(message, action, {
      duration: 3000
    })
  }
}
