import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import * as SecureLS from 'secure-ls';
import * as colombia from "colombia-holidays";
import { FamilydayService } from 'src/app/service/familyday.service';

@Component({
  selector: 'app-editobject-dialog',
  templateUrl: './editobject-dialog.component.html',
  styleUrls: ['./editobject-dialog.component.scss']
})
export class EditobjectDialogComponent implements OnInit {
  ls: SecureLS
  userRole: string
  objectName: string
  mode: string
  form = new FormGroup({})
  model = {}
  options: FormlyFormOptions = {
    formState: {
      disabled: false
    }
  }
  fields: FormlyFieldConfig[] = []
  // requests
  isLoading = false
  errorMessage = ""
  // objects
  objectType = ""
  employee
  vacationValidation

  constructor(
    public dialogRef: MatDialogRef<EditobjectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public object: any,
    private familyMemberService: FamilydayService,
    public snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.ls = new SecureLS({ encodingType: "aes" })
    this.userRole = this.ls.get("ur")
    this.mode = this.object.mode
    this.employee = this.object.employee
    this.vacationValidation = this.object.vacationValidation
    this.objectType = this.object.type
    const object = this.object.object
    this.configObject(this.objectType, object)
  }

  configObject(objectType, object) {
    if (this.mode === "view") {
      this.options.formState.disabled = true
    }
    switch (objectType) {
      case "familyday":
        this.objectName = "Día de la familia"
        this.model = object
        this.fields = [
          {
            key: "description",
            type: "textarea",
            templateOptions: {
              label: "Descripción",
              placeholder: "Ingrese la descripción",
              rows: 3,
              grow: true,
              required: true
            },
            expressionProperties: {
              "templateOptions.disabled": "formState.disabled"
            }
          },
          {
            key: "start_date",
            type: "datepicker",
            templateOptions: {
              label: "Fecha de inicio",
              placeholder: "Ingrese la fecha de inicio",
              required: true
            },
            expressionProperties: {
              "templateOptions.disabled": "formState.disabled"
            }
          },
          {
            key: "end_date",
            type: "datepicker",
            templateOptions: {
              label: "Fecha de finalización",
              placeholder: "Ingrese la fecha de finalización",
              required: true
            },
            expressionProperties: {
              "templateOptions.disabled": "formState.disabled"
            }
          },
          {
            key: "organizated",
            type: "select",
            templateOptions: {
              label: "Organizado por",
              placeholder: "Seleccione quien es el encargado del evento",
              required: true,
              options: [
                { value: "1", label: "Recursos Humanos" },
                { value: "2", label: "Empleado" }
              ]
            },
            expressionProperties: {
              "templateOptions.disabled": "formState.disabled"
            }
          },
          {
            key: "year",
            type: "input",
            templateOptions: {
              label: "Año",
              placeholder: "Ingrese la descripción",
              required: true
            },
            expressionProperties: {
              "templateOptions.disabled": "formState.disabled"
            }
          },
          {
            key: "semester",
            type: "input",
            templateOptions: {
              label: "Semestre",
              placeholder: "Ingrese la descripción",
              required: true
            },
            expressionProperties: {
              "templateOptions.disabled": "formState.disabled"
            }
          }
        ]
        break
      case "familymember":
        this.objectName = "Miembro de mi familia"
        this.model = object
        this.fields = [
          {
            key: "dni",
            type: "input",
            templateOptions: {
              label: "Identificación",
              placeholder: "Ingrese el DNI",
              required: true,
              disabled: true
            }
          },
          {
            key: "full_name",
            type: "input",
            templateOptions: {
              label: "Nombre completo",
              placeholder: "Ingrese el nombre",
              required: true
            },
            expressionProperties: {
              "templateOptions.disabled": "formState.disabled"
            }
          },
          {
            key: "birthday",
            type: "datepicker",
            templateOptions: {
              label: "Fecha de nacimiento",
              placeholder: "Ingrese la fecha de nacimiento",
              required: true
            },
            expressionProperties: {
              "templateOptions.disabled": "formState.disabled"
            }
          }
        ]

        if (this.mode === "view") {
          this.fields.push(
            {
              key: "state",
              type: "select",
              templateOptions: {
                label: "Deseo que vaya",
                placeholder: "Seleccione quien quiere llevar",
                required: true,
                options: [
                  { value: "1", label: "NO SELECCIONADO (NO)" },
                  { value: "2", label: "SELECCIONADO (SI)" }
                ]
              },
              expressionProperties: {
                "templateOptions.disabled": "formState.disabled"
              }
            })
        }
        break
      case "familydayemployee":
        this.objectName = "mi día de la familia"
        this.model = object
        this.fields = [
          {
            key: "start_date",
            type: "datepicker",
            templateOptions: {
              label: "Fecha de inicio",
              placeholder: "Ingrese la fecha de inicio",
              required: true,
              disabled: true
            }
          },
          {
            key: "end_date",
            type: "datepicker",
            templateOptions: {
              label: "Fecha de finalización",
              placeholder: "Ingrese la fecha de finalización",
              required: true,
              disabled: true
            }
          },
          {
            key: "selected_date",
            type: "datepicker",
            templateOptions: {
              label: "Fecha seleccionada",
              placeholder: "Ingrese una fecha entre el rango de fechas anteriores",
              required: true
            },
            expressionProperties: {
              "templateOptions.disabled": "formState.disabled"
            }
          },
          {
            key: "contact_name",
            type: "input",
            templateOptions: {
              label: "Nombre de contacto",
              placeholder: "Ingrese el nombre"
            },
            expressionProperties: {
              "templateOptions.disabled": "formState.disabled"
            }
          },
          {
            key: "contact_phone",
            type: "input",
            templateOptions: {
              type: "number",
              label: "Teléfono de contacto",
              placeholder: "Ingrese el teléfono"
            },
            expressionProperties: {
              "templateOptions.disabled": "formState.disabled"
            }
          },
          {
            key: "description",
            type: "textarea",
            templateOptions: {
              label: "Descripción",
              placeholder: "Ingrese la descripción",
              rows: 3,
              grow: true,
              required: true,
              disabled: true
            }
          },
          {
            key: "year",
            type: "input",
            templateOptions: {
              label: "Año",
              placeholder: "Ingrese la descripción",
              required: true,
              disabled: true
            }
          },
          {
            key: "semester",
            type: "input",
            templateOptions: {
              label: "Semestre",
              placeholder: "Ingrese la descripción",
              required: true,
              disabled: true
            }
          },
        ]
        break
      default:
        break
    }
  }

  submit(model) {
    if (this.form.valid) {
      this.isLoading = true
      let message = ""
      switch (this.objectType) {
        case "familymember":
          /* -------------------- DESCOMENTAR PARA PROBAR ------------------------------- */
          console.log(model)
          /* this.familyMemberService.putFamilyMember(model).subscribe(
            p => {
              message = p.message
              if (message !== "Error") {
                this.launchMessage("El objeto se actualizó correctamente")
                this.dialogRef.close(true)
              }
            },
            e => this.launchMessage(e),
            () => {
              this.isLoading = false
            }
          ) */
          break
        case "familydayemployee":
          let now = new Date();
          let today = now.getFullYear() + "-" + (now.getMonth() + 1) + "-" + now.getDate() + " " + now.getHours() + ":" + now.getMinutes() + ":" + now.getSeconds();
          let model_start_date = new Date(model.start_date);
          let model_end_date = new Date(model.end_date);
          let holidays = colombia.getColombiaHolidaysByYear(model_start_date.getFullYear()).map((element) => { return element.holiday })

          if (model.selected_date >= model_start_date && model.selected_date <= model_end_date) {
            if (!holidays.includes(model.selected_date.toISOString().split('T')[0])) {
              if (model.selected_date.getDay() != 0 && model.selected_date.getDay() != 6) {

                let vacationValidationF = this.vacationValidationFunction(model.selected_date)

                if (vacationValidationF === true) {
                  this.launchMessage("Verifica que la fecha no coincida con el rango de vacaciones aprobadas o en proceso de solicitud.")
                } else {

                  let requestID = ""
                  let sendRequestObject = {
                    date: today,
                    employee_id: this.employee.id,
                    boss_id: this.employee.boss_id,
                    start_date: model.selected_date,
                    type: "2", // Type 2 is familyDay
                    status: "1",
                    bu_code: this.employee.bu_code,
                    contact_name: model.contact_name,
                    contact_phone: model.contact_phone,
                    made_by: "2" // 1 Human resources - 2 Employee
                  }

                  /* ------ PROBAR Y ELIMINAR COMENTARIO ------ */
                  /* this.familyMemberService.postfamilyDayRequest(sendRequestObject).subscribe(
                    p => {
                      if (p.message) {
                        requestID = p.request_id
                        this.launchMessage("Se ha creado tu solicitud exitosamente")
                        this.dialogRef.close(true)
                      }
                    },
                    e => this.launchMessage(e),
                    () => {
                      this.isLoading = false
                    }
                  ) */
                  console.log("TERMINADO POR EMPLEADO")
                }
              } else {
                this.launchMessage("La fecha seleccionada debe ser un día hábil.")
              }
            } else {
              this.launchMessage("La fecha seleccionada no puede ser festiva.");
            }
          } else {
            this.launchMessage("Debes seleccionar una fecha que esté entre la fecha de inicio y la fecha de finalización.")
          }
          break
        default:
          break
      }
    }
  }

  vacationValidationFunction(selectedDate) {
    let start_date;
    let end_date;

    if (this.vacationValidation.length > 0) {
      this.vacationValidation.forEach((element) => {
        start_date = new Date(element.start_date);
        end_date = new Date(element.end_date);
        if (selectedDate >= start_date && selectedDate <= end_date) {
          return true;
        }
      })
    }

    return false;
  }

  launchMessage(message: string) {
    this.errorMessage = ""
    this.isLoading = false
    const action = "OK"
    this.snackBar.open(message, action, {
      duration: 4000
    })
  }

  onNoClick(): void {
    this.dialogRef.close()
  }
}

export interface SelectData {
  value: string
  label: string
}