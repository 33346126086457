import { Component, OnInit } from "@angular/core"
import { Router } from "@angular/router"
import * as SecureLS from "secure-ls"
import { FormGroup, FormBuilder, FormControl, Validators } from "@angular/forms"
import { MediaObserver, MediaChange } from "@angular/flex-layout"
import { Subscription } from "rxjs"
import { MatSnackBar } from "@angular/material"
import { GlobalVariable } from "src/app/config/global"
import { User } from "src/app/model/user"
import { UserService } from "src/app/service/user.service"
import { Employee } from "src/app/model/employee"
import { EmployeeService } from "src/app/service/employee.service"
import { BusinessUnit } from "src/app/model/businessUnit"
import { BusinessunitService } from "src/app/service/businessunit.service"

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"]
})
export class ProfileComponent implements OnInit {
  AppName = ""
  currentScreenWidth: string = ""
  flexMediaWatcher: Subscription
  sidenavWidth = 4.2
  ls: SecureLS
  // user information
  user: User
  isLoading = false
  errorMessage = ""
  profileUpdatesForm: FormGroup
  // employee information
  employee = <Employee>{
    id: ""
  }
  employees: Employee[]
  // business units information
  businessUnits: BusinessUnit[] = []
  selectedBusinessUnit = <BusinessUnit>{
    id: ""
  }

  constructor(
    private router: Router,
    private userService: UserService,
    private employeeService: EmployeeService,
    private businessunitService: BusinessunitService,
    public snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private mediaObserver: MediaObserver
  ) {
    this.flexMediaWatcher = mediaObserver.media$.subscribe(
      (change: MediaChange) => {
        if (change.mqAlias !== this.currentScreenWidth) {
          this.currentScreenWidth = change.mqAlias
        }
      }
    )
  }

  ngOnInit() {
    this.AppName = GlobalVariable.APP_NAME
    this.ls = new SecureLS({ encodingType: "aes" })
    if (!this.validateSession()) {
      this.router.navigate(["/login"])
      return
    }
    this.getEmployeeData()
    this.initForms()
  }

  ngOnDestroy(): void {
    this.flexMediaWatcher.unsubscribe()
  }

  increase() {
    this.sidenavWidth = 16
  }
  decrease() {
    this.sidenavWidth = 4.2
  }

  validateSession(): boolean {
    if (localStorage.getItem("uid") === null) {
      return false
    } else {
      return true
    }
  }

  /** function to init the forms */
  initForms() {
    this.profileUpdatesForm = this.formBuilder.group({
      password: new FormControl("", [
        Validators.required,
        Validators.minLength(6)
      ])
    })
  }

  getErrorMessage(component: string) {
    let errorMessage = ""
    switch (component) {
      case "password":
        errorMessage = this.profileUpdatesForm
          .get("password")
          .hasError("required")
          ? "campo requerido"
          : ""
        errorMessage = this.profileUpdatesForm
          .get("password")
          .hasError("minlength")
          ? "la contraseña debe tener mínimo (6) caracteres"
          : ""
        break
    }
    return errorMessage
  }

  /** function to get the current logged in user, call the function to get projects or register the API Token */
  getEmployeeData() {
    this.employee = <Employee>{
      id: ""
    }
    const userid = this.ls.get("uid")
    this.employeeService.getEmployee(userid).subscribe(
      p => {
        this.employees = p.results !== undefined ? p.results : null
        this.employee = p.results !== undefined ? p.results[0] : null
      },
      e => this.launchMessage(e),
      () => {
        if (this.employee) {
          this.getBusinessUnitByEmployee()
        }
      }
    )
  }

  getBusinessUnitByEmployee() {
    this.selectedBusinessUnit = <BusinessUnit>{
      id: ""
    }
    this.employees.map(employee => {
      let bu: BusinessUnit
      this.businessunitService.getBusinessUnit(employee.bu_code).subscribe(
        p => (bu = p.results !== undefined ? p.results[0] : null),
        e => this.launchMessage(e),
        () => {
          this.isLoading = false
          employee.bu_name = bu.name
          this.businessUnits.push(bu)
          this.selectedBusinessUnit = this.businessUnits.find(
            bu => bu.id == this.employee.bu_code
          )
        }
      )
    })
  }

  updateProfile(form: FormGroup) {
    this.isLoading = true
    let message = ""
    const submission = this.buildJSONToPutProfileUpdates(form.value.password)
    this.userService.putUpdateProfile(submission).subscribe(
      p => {
        message = p.message
        if (message === "Updated") {
          this.launchMessage("La contraseña se ha actualizado exitosamente")
        }
      },
      e => this.launchMessage(e),
      () => {
        this.isLoading = false
      }
    )
  }

  /** this method builds the json using the data enter in the form */
  buildJSONToPutProfileUpdates(password: string): any {
    const now = new Date()
    const month = now.getMonth() + 1
    const x = {
      u: this.employee.id,
      p: encodeURIComponent(window.btoa(password)),
      datetime:
        now.getFullYear() +
        "-" +
        month +
        "-" +
        now.getDate() +
        " " +
        now.getHours() +
        ":" +
        now.getMinutes() +
        ":" +
        now.getSeconds()
    }
    return x
  }

  launchMessage(message: string) {
    this.errorMessage = ""
    this.isLoading = false
    const action = "OK"
    this.snackBar.open(message, action, {
      duration: 4000
    })
  }
}
