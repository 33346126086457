import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-observation-dialog',
  templateUrl: './observation-dialog.component.html',
  styleUrls: ['./observation-dialog.component.scss']
})
export class ObservationDialogComponent implements OnInit {
  observation = ""

  constructor(
    public dialogRef: MatDialogRef<ObservationDialogComponent>,
  ) { }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close()
  }

}
