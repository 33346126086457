import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { MatDialog, MatSnackBar, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import * as momento from "moment"
import * as SecureLS from 'secure-ls';
import { GlobalVariable } from 'src/app/config/global';
import { BusinessUnit } from 'src/app/model/businessUnit';
import { Employee } from 'src/app/model/employee';
import { FamilyDay } from 'src/app/model/familyDay';
import { FamilyMember } from 'src/app/model/familyMember';
import { BusinessunitService } from 'src/app/service/businessunit.service';
import { EmployeeService } from 'src/app/service/employee.service';
import { FamilydayService } from 'src/app/service/familyday.service';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { CreateobjectDialogComponent } from '../createobject-dialog/createobject-dialog.component';
import { EditobjectDialogComponent } from '../editobject-dialog/editobject-dialog.component';
import { ObservationDialogComponent } from '../observation-dialog/observation-dialog.component';
import { RequestService } from 'src/app/service/request.service';

@Component({
  selector: 'app-familydayrequest',
  templateUrl: './familydayrequest.component.html',
  styleUrls: ['./familydayrequest.component.scss']
})
export class FamilydayrequestComponent implements OnInit {
  AppName = ""
  currentScreenWidth: string = ""
  flexMediaWatcher: Subscription
  sidenavWidth = 4.2
  ls: SecureLS
  isLoading = false
  errorMessage = ""
  selectedDate = ""
  contactName = ""
  contactPhone = ""
  // Employee information
  employee: Employee
  employees: Employee[]
  // BusinessUnit information
  selectedBusinessUnit = <BusinessUnit>{
    id: ""
  }
  businessUnits: BusinessUnit[] = []
  // familyday information
  familyDays: any[]
  dataFamilyDay: FamilyDay[] = []
  familyDayDataSource = new MatTableDataSource(this.dataFamilyDay)
  familyDayDisplayedColumns = ["description", "semester", "start_date", "end_date", "organizated", "actions"]
  // familymember information
  familyMembers: any[]
  dataFamilyMember: FamilyMember[] = []
  familyMemberDataSource = new MatTableDataSource(this.dataFamilyMember)
  familyMemberDisplayedColumns = ["dni", "full_name", "birthday", "state", "actions"]
  // familymember information (confirmed)
  familyMembersC: any[]
  dataFamilyMemberC: FamilyMember[] = []
  familyMemberDataSourceC = new MatTableDataSource(this.dataFamilyMemberC)
  familyMemberConfirmedColumns = ["dni", "full_name", "observation"]
  // HumanResources information
  humanResources
  //Request
  requests = []
  requestsFilter = []
  inProcessRequests = []
  approvedRequests = []
  rejectedRequests = []
  processAndApprovedFilterFamilyD = []
  processAndApprovedFilterVacations = []
  // Mat-Tab
  selectedIndex = 0
  matIndex1 = false
  matIndex2 = true
  matIndex3 = true

  constructor(
    private router: Router,
    public snackBar: MatSnackBar,
    private employeeService: EmployeeService,
    private businessunitService: BusinessunitService,
    private familyDayService: FamilydayService,
    private requestService: RequestService,
    private http: HttpClient,
    private mediaObserver: MediaObserver,
    public dialog: MatDialog,
  ) {
    this.flexMediaWatcher = mediaObserver.media$.subscribe(
      (change: MediaChange) => {
        if (change.mqAlias !== this.currentScreenWidth) {
          this.currentScreenWidth = change.mqAlias
        }
      }
    )
  }

  ngOnInit() {
    this.AppName = GlobalVariable.APP_NAME
    this.ls = new SecureLS({ encodingType: "aes" })
    if (!this.validateSession()) {
      this.router.navigate(["/login"])
      return
    }
    //this.initForms()
    this.getEmployeeData()
  }

  ngOnDestroy(): void {
    this.flexMediaWatcher.unsubscribe()
  }

  /** validate a valid session exists*/
  validateSession(): boolean {
    if (localStorage.getItem("uid") === null) {
      return false
    } else {
      return true
    }
  }

  increase() {
    this.sidenavWidth = 16
  }
  decrease() {
    this.sidenavWidth = 4.2
  }

  /** Event handler when business unit select component changes */
  buSelectionChange(event: { value: BusinessUnit }) {
    this.selectedBusinessUnit = event.value
    this.employee = this.employees.find(e => e.bu_code === event.value.id)
  }

  /** function to get the current logged in user, call the function to get projects or register the API Token */
  getEmployeeData() {
    this.isLoading = true
    this.employee = <Employee>{
      id: ""
    }
    const userid = this.ls.get("uid")
    this.employeeService.getEmployee(userid).subscribe(
      p => {
        this.employees = p.results !== undefined ? p.results : []
        this.employee = p.results !== undefined ? p.results[0] : null
      },
      e => this.launchMessage(e),
      () => {
        if (this.employee.boss_id.trim() == "" && this.employee.boss_name.trim() == "" && this.employee.boss_email.trim() == "") {
          this.businessunitService.getBusinessUnit(this.employee.bu_code).subscribe(
            p => (this.humanResources = p.results !== undefined ? p.results[0] : ""),
            e => this.launchMessage(e),
            () => {
            }
          )
        }
        this.isLoading = false
        if (this.employee) {
          this.getBusinessUnitByEmployee()
        }
      }
    )
  }

  getBusinessUnitByEmployee() {
    this.isLoading = true
    this.employees.map(employee => {
      let bu: BusinessUnit
      this.businessunitService.getBusinessUnit(employee.bu_code).subscribe(
        p => (bu = p.results !== undefined ? p.results[0] : null),
        e => this.launchMessage(e),
        () => {
          this.isLoading = false
          if (bu) {
            this.businessUnits.push(bu)
          }
          this.selectedBusinessUnit = this.businessUnits.find(
            bu => bu.id == this.employee.bu_code
          )
          if (this.selectedBusinessUnit) {
            //call the function to get family day information
            this.getFamilyDaysByBU()
            this.getFamilyMembers()
            this.getRequest()
          }
        }
      )
    })
  }

  getFamilyDaysByBU() {
    this.isLoading = true

    /* ---------------------- DESCOMENTAR PARA PROBAR ---------------------------- */

    /* this.familyDayService.getFamilyDayByBU(this.employee.bu_code).subscribe(
      p => {
        this.familyDays = p.results !== undefined ? p.results : []
      },
      e => this.launchMessage(e),
      () => {
        this.isLoading = false
        if (this.familyDays.length > 0) {
          // Dates greater than today
          let today = new Date();
          let todayAux = today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
          let todayFilter = new Date(todayAux)
          console.log(this.familyDays)
          this.familyDays = this.familyDays.filter((element) => {
            return new Date(element.start_date) >= todayFilter;
          })
          console.log(this.familyDays)

          this.setFamilyDayDatasource()
        }
      }
    ) */

    /* Esto se elimina cuando lo de arriba se prueba */
    this.familyDays = [{
      id: "1",
      bu_code: "C11",
      year: "2021",
      semester: "2",
      start_date: "2021-07-20T00:00:00.000",
      end_date: "2021-08-01T00:00:00.000",
      organizated: "2",
      description: "Esto es una prueba"
    },
    {
      id: "2",
      bu_code: "C11",
      year: "2022",
      semester: "1",
      start_date: "2021-05-04T00:00:00.000",
      end_date: "2021-06-01T00:00:00.000",
      organizated: "1",
      description: "Esto es una prueba x2"
    }]




    this.isLoading = false
    if (this.familyDays.length > 0) {
      /* Probar este filtro con mas fechas */
      /* let today = new Date();
      let todayAux = today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
      let todayFilter = new Date(todayAux)

      console.log(this.familyDays)
      this.familyDays = this.familyDays.filter((element) => {
        return new Date(element.start_date) >= todayFilter;
      })
      console.log(this.familyDays) */

      this.setFamilyDayDatasource()
    }
    /* fin eliminar */

  }

  setFamilyDayDatasource() {
    this.familyDays.forEach(element => {
      element.editable = true
    });
    this.familyDayDataSource = new MatTableDataSource(this.familyDays)
    this.familyDayDataSource.filterPredicate = function (
      data: FamilyDay,
      filterValue: string
    ) {
      return (
        data.year /** replace this with the column name you want to filter */
          .trim()
          .toLocaleLowerCase()
          .indexOf(filterValue.trim().toLocaleLowerCase()) >= 0 ||
        data.semester
          .trim()
          .toLowerCase()
          .indexOf(filterValue.trim().toLocaleLowerCase()) >= 0 ||
        data.start_date
          .trim()
          .toLowerCase()
          .indexOf(filterValue.trim().toLocaleLowerCase()) >= 0
      )
    }
  }

  applyFamilyDayFilter(filterValue: string) {
    filterValue = filterValue.trim() // Remove whitespace
    filterValue = filterValue.toLowerCase() // MatTableDataSource defaults to lowercase matches
    this.familyDayDataSource.filter = filterValue
  }

  getFamilyMembers() {
    this.isLoading = true

    /* ---------------------- DESCOMENTAR PARA PROBAR ---------------------------- */
    /* this.familyDayService.getFamilyMembersByEmployee(this.employee.id).subscribe(
      p => {
        this.familyMembers = p.results !== undefined ? p.results : []
      },
      e => this.launchMessage(e),
      () => {
        this.isLoading = false
        if (this.familyMembers.length > 0) {
          this.setFamilyMemberDatasource()
        }
      }
    ) */

    /* Esto se elimina cuando lo de arriba se prueba */
    this.familyMembers = [{
      dni: "10654987896",
      full_name: "Pepito perez",
      birthday: "2021-05-04",
      employee_id: "3",
    },
    {
      dni: "15555555556",
      full_name: "Ana perez",
      birthday: "2021-05-10",
      employee_id: "3",
    }]

    this.isLoading = false
    if (this.familyMembers.length > 0) {
      this.setFamilyMemberDatasource()
    }

    /* fin eliminar */
  }

  setFamilyMemberDatasource() {
    this.familyMembers.forEach(element => {
      element.editable = true
      element.state = "1"
    });
    this.familyMemberDataSource = new MatTableDataSource(this.familyMembers)
    this.familyMemberDataSource.filterPredicate = function (
      data: FamilyMember,
      filterValue: string
    ) {
      return (
        data.dni /** replace this with the column name you want to filter */
          .trim()
          .toLocaleLowerCase()
          .indexOf(filterValue.trim().toLocaleLowerCase()) >= 0 ||
        data.full_name
          .trim()
          .toLowerCase()
          .indexOf(filterValue.trim().toLocaleLowerCase()) >= 0
      )
    }
  }

  applyFamilyMemberFilter(filterValue: string) {
    filterValue = filterValue.trim() // Remove whitespace
    filterValue = filterValue.toLowerCase() // MatTableDataSource defaults to lowercase matches
    this.familyMemberDataSource.filter = filterValue
  }

  setFamilyMemberDatasourceConformed() {
    this.familyMembersC.forEach(element => {
      element.editable = true
      element.observation = ""
    });
    this.familyMemberDataSourceC = new MatTableDataSource(this.familyMembersC)
  }

  viewObject(familyDay: FamilyDay) {
    const dialogRef = this.dialog.open(EditobjectDialogComponent, {
      data: {
        type: "familyday",
        mode: "view",
        object: familyDay,
        bu: this.selectedBusinessUnit
      }
    })
  }

  selectObject(familyDay: FamilyDay) {

    if (familyDay.organizated == "1") {

      // family day and vacation validations (human resources)
      let familiDayValidation = this.familyDayValidation(familyDay)
      let vacationValidation = this.vacationValidation(familyDay)

      if (familiDayValidation === true) {
        this.launchMessage("Solo puedes solicitar un día de la familia por semestre.")
      } else {
        if (vacationValidation === true) {
          this.launchMessage("Verifica que la fecha no coincida con el rango de vacaciones aprobadas o en proceso de solicitud.")
        } else {

          // human resources
          const dialogRefConfirmationHumanResources = this.dialog.open(ConfirmationDialogComponent, {
            width: "350px",
            data: "¿Estás seguro que quieres crear la solicitud para la fecha " + familyDay.start_date + "?"
          })
          dialogRefConfirmationHumanResources.afterClosed().subscribe(result => {
            if (result) {
              this.matIndex1 = true;
              this.matIndex2 = false;
              this.matIndex3 = true;
              this.selectedIndex = 1;
              this.selectedDate = familyDay.start_date;
            }
          })
        }
      }
    } else {
      if (familyDay.organizated == "2") {

        // family day validations (employee)
        let familiDayValidation = this.familyDayValidation(familyDay)
        if (familiDayValidation === true) {
          this.launchMessage("Solo puedes solicitar un día de la familia por semestre.")
        } else {

          // Employee
          const dialogRefConfirmationEmployee = this.dialog.open(ConfirmationDialogComponent, {
            width: "350px",
            data: "¿Estás seguro que quieres escoger una fecha entre: " + familyDay.start_date + " y " + familyDay.end_date + "?"
          })
          dialogRefConfirmationEmployee.afterClosed().subscribe(result => {
            if (result) {
              const dialogRef = this.dialog.open(EditobjectDialogComponent, {
                data: {
                  type: "familydayemployee",
                  mode: "confirm",
                  object: familyDay,
                  employee: this.employee,
                  vacationValidation: this.processAndApprovedFilterVacations
                }
              })

              dialogRef.afterClosed().subscribe(result => {
                if (result) {
                  this.getRequest()
                }
              })
            }
          })
        }
      }
    }
  }

  familyDayValidation(familyDay: FamilyDay) {
    let year;
    let month;
    let selectedYear = parseInt(familyDay.year)

    if (this.processAndApprovedFilterFamilyD.length > 0) {
      if (familyDay.semester === "1") {
        // Semester 1: month <= 6
        this.processAndApprovedFilterFamilyD.forEach((element) => {
          year = new Date(element.start_date).getFullYear();
          month = new Date(element.start_date).getMonth() + 1;
          if (year == selectedYear && month <= 6) {
            return true;
          }
        })
      } else {
        // Semester 2: month >= 7
        this.processAndApprovedFilterFamilyD.forEach((element) => {
          year = new Date(element.start_date).getFullYear();
          month = new Date(element.start_date).getMonth() + 1;
          if (year == selectedYear && month >= 7) {
            return true;
          }
        })
      }
    }

    return false;
  }

  vacationValidation(familyDay: FamilyDay) {
    let start_date;
    let end_date;
    let selectedDate = new Date(familyDay.start_date)

    if (this.processAndApprovedFilterVacations.length > 0) {
      this.processAndApprovedFilterVacations.forEach((element) => {
        start_date = new Date(element.start_date);
        end_date = new Date(element.end_date);

        if (selectedDate >= start_date && selectedDate <= end_date) {
          return true;
        }
      })
    }
    return false;
  }

  selectTab(index: number) {
    if (index === 1) {
      this.matIndex1 = true;
      this.matIndex2 = true;
      this.matIndex3 = false;
      this.selectedIndex = 2;

      this.familyMembersC = this.familyMembers.filter((element) => {
        return element.state == "2";
      })
      this.setFamilyMemberDatasourceConformed()

    } else {
      if (index === 2) {
        let now = new Date();
        let today = now.getFullYear() + "-" + (now.getMonth() + 1) + "-" + now.getDate() + " " + now.getHours() + ":" + now.getMinutes() + ":" + now.getSeconds();
        let requestID = ""
        let sendRequestObject = {
          date: today,
          employee_id: this.employee.id,
          boss_id: this.employee.boss_id,
          start_date: this.selectedDate,
          type: "2", // Type 2 is familyDay
          status: "1",
          bu_code: this.employee.bu_code,
          contact_name: this.contactName,
          contact_phone: this.contactPhone,
          made_by: "1" // 1 Human resources - 2 Employee
        }

        /* ------ PROBAR Y ELIMINAR COMENTARIO ------ */
        /* this.familyDayService.postfamilyDayRequest(sendRequestObject).subscribe(
          p => {
            if (p.message) {
              requestID = p.request_id
            }
          },
          e => this.launchMessage(e),
          () => {
            this.isLoading = false
            this.familyMembersC.forEach(element => {
              element.request_id = requestID
              this.familyDayService.postFamilyMemberRequest(element).subscribe(
                p => {
                },
                e => this.launchMessage(e),
                () => {
                }
              )
            });

            const submission = this.buildJSONToPutRequestUpdates(
              requestID,
              "5",
              "Aceptada"
            )
            this.requestService.putRequest(submission).subscribe(
              p => {
                if (p.message === "Updated") {
                  this.launchMessage("La solicitud se actualizó correctamente")
                }
              },
              e => this.launchMessage(e),
              () => {
                this.getRequest()
              }
            )
          }
        ) */
        console.log("TERMINADO POR RECURSOS HUMANOS")

        this.matIndex1 = false;
        this.matIndex2 = true;
        this.matIndex3 = true;
        this.selectedIndex = 0;
      }
    }
  }

  /** this method builds the json using the data enter in the form */
  buildJSONToPutRequestUpdates(
    request: string,
    status: string,
    observation: string
  ): any {
    const x = {
      id: request,
      status: status,
      observation: observation,
      boss_id: this.employee.boss_id
    }
    return x
  }

  // Actions for family member
  createFamilyMember() {
    const dialogRef = this.dialog.open(CreateobjectDialogComponent, {
      data: {
        type: "familymember",
        object: { employee_id: this.employee.id },
        bu: this.selectedBusinessUnit
      }
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getFamilyMembers()
      }
    })
  }

  viewMemberObject(familyMember: FamilyMember) {
    console.log("VER")
    const dialogRef = this.dialog.open(EditobjectDialogComponent, {
      data: {
        type: "familymember",
        mode: "view",
        object: familyMember,
        bu: this.selectedBusinessUnit
      }
    })
  }

  editMemberObject(familyMember: FamilyMember) {
    const object = { ...familyMember }
    Object.keys(object).map(
      k =>
      (object[k] =
        typeof object[k] == "string" ? object[k].trim() : object[k])
    )
    const dialogRef = this.dialog.open(EditobjectDialogComponent, {
      data: {
        type: "familymember",
        mode: "edit",
        object: object,
        bu: this.selectedBusinessUnit
      }
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getFamilyMembers()
      }
    })
  }

  deleteMemberObject(familyMember: FamilyMember) {
    let message = ""

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "350px",
      data: "¿Estás seguro que quieres eliminar este elemento?"
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        /* ---------------------- DESCOMENTAR PARA PROBAR ---------------------------- */
        console.log(familyMember.dni)
        console.log(familyMember)
        console.log("Borrado")

        /* this.isLoading = true
        this.familyDayService.deleteFamilyMember(familyMember.dni).subscribe(
          p => {
            if (p.message !== "Error") {
              this.launchMessage(
                "El objeto se ha eliminado correctamente"
              )
            }
          },
          e => this.launchMessage(e),
          () => {
            this.isLoading = false
            this.getFamilyMembers()
          }
        ) */
      }
    })
  }

  changeState(familyMember: FamilyMember) {
    // State 1 is an unselected family member - State 2 is an selected family member
    if (familyMember.state === "1") {
      familyMember.state = "2"
    } else {
      if (familyMember.state === "2") {
        familyMember.state = "1"
      }
    }
  }

  openDialogObservation(familyMember: FamilyMember) {
    const dialogRef = this.dialog.open(ObservationDialogComponent, {
      width: "450px",
    })
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        familyMember.observation = result
      }
    })
  }

  returnPage() {
    this.matIndex1 = true;
    this.matIndex2 = false;
    this.matIndex3 = true;
    this.selectedIndex = 1;
  }

  getRequest() {
    this.requestService
      .getRequests(this.employee.id, this.employee.bu_code)
      .subscribe(
        p => {
          this.requests = p.results !== undefined ? p.results : []
        },
        e => this.launchMessage(e),
        () => {
          this.isLoading = false

          // Family Day filter
          this.requestsFilter = this.requests.filter(r => r.type === "2")

          if (this.requestsFilter.length > 0) {
            momento.locale("es")
            this.requestsFilter.forEach(request => {
              request.date = momento(request.date, "YYYY-MM-DD").format(
                "DD MMMM YYYY"
              )
              request.start_date = momento(
                request.start_date,
                "YYYY-MM-DD"
              ).format("DD MMMM YYYY")
              /* request.end_date = momento(request.end_date, "YYYY-MM-DD").format(
                "DD MMMM YYYY"
              )
              request.entrance_date = momento(
                request.entrance_date,
                "YYYY-MM-DD"
              ).format("DD MMMM YYYY") */
            })
            this.inProcessRequests = this.requestsFilter.filter(
              r => r.status === "1" || r.status === "2"
            )
            this.approvedRequests = this.requestsFilter.filter(r => r.status === "5")
            this.rejectedRequests = this.requestsFilter.filter(
              r => r.status === "3" || r.status === "4"
            )

            // family day and vacations filter
            let typeFilterV = this.requests.filter(r => r.type === "1")
            this.processAndApprovedFilterFamilyD = this.requestsFilter.filter(r => r.status === "1" || r.status === "2" || r.status === "5")
            this.processAndApprovedFilterVacations = typeFilterV.filter(r => r.status === "1" || r.status === "2" || r.status === "5")
          }
        }
      )
  }

  launchMessage(message: string) {
    this.errorMessage = ""
    this.isLoading = false
    const action = "OK"
    this.snackBar.open(message, action, {
      duration: 4000
    })
  }

}
