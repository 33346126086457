import { BrowserModule } from '@angular/platform-browser'
import { NgModule, LOCALE_ID } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { FormlyModule } from "@ngx-formly/core"
import { FormlyMaterialModule } from "@ngx-formly/material"
import { FormlyMatDatepickerModule } from "@ngx-formly/material/datepicker"
import { registerLocaleData } from '@angular/common'
import localeES from '@angular/common/locales/es'
import { HttpClientModule } from '@angular/common/http'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import {
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
  MatStepperModule,
  MatFormField
} from '@angular/material'
import { CdkTableModule } from '@angular/cdk/table'
import { BnNgIdleService } from 'bn-ng-idle'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { LoginComponent } from './view/login/login.component'
import { HomeComponent } from './view/home/home.component'
import { AccountstatementComponent } from './view/accountstatement/accountstatement.component'
import { TopmenuComponent } from './view/topmenu/topmenu.component'
import { EmploymentrecordComponent } from './view/employmentrecord/employmentrecord.component'
import { DonationcertificateComponent } from './view/donationcertificate/donationcertificate.component'
import { SidenavComponent } from './view/sidenav/sidenav.component'
import { FooterComponent } from './view/footer/footer.component'
import { EarningscertificateComponent } from './view/earningscertificate/earningscertificate.component'
import { ProfileComponent } from './view/profile/profile.component'
import { PasswordResetComponent } from './view/password-reset/password-reset.component'
import { ConfirmationDialogComponent } from './view/confirmation-dialog/confirmation-dialog.component';
import { VacationrequestComponent } from './view/vacationrequest/vacationrequest.component';
import { SelectroleComponent } from './view/selectrole/selectrole.component';
import { FamilydayrequestComponent } from './view/familydayrequest/familydayrequest.component';
import { EditobjectDialogComponent } from './view/editobject-dialog/editobject-dialog.component';
import { CreateobjectDialogComponent } from './view/createobject-dialog/createobject-dialog.component';
import { ObservationDialogComponent } from './view/observation-dialog/observation-dialog.component'
registerLocaleData(localeES, 'es')
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    AccountstatementComponent,
    TopmenuComponent,
    EmploymentrecordComponent,
    DonationcertificateComponent,
    SidenavComponent,
    FooterComponent,
    EarningscertificateComponent,
    ProfileComponent,
    PasswordResetComponent,
    ConfirmationDialogComponent,
    VacationrequestComponent,
    SelectroleComponent,
    FamilydayrequestComponent,
    EditobjectDialogComponent,
    CreateobjectDialogComponent,
    ObservationDialogComponent
  ],
  entryComponents: [
    ConfirmationDialogComponent,
    EditobjectDialogComponent,
    CreateobjectDialogComponent,
    ObservationDialogComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    CdkTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    FormlyModule.forRoot(),
    FormlyMaterialModule,
    FormlyMatDatepickerModule
  ],
  providers: [BnNgIdleService, { provide: LOCALE_ID, useValue: 'es' }],
  bootstrap: [AppComponent]
})
export class AppModule { }
