import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable } from 'rxjs'
import { GlobalVariable } from '../config/global'

@Injectable({
  providedIn: 'root'
})
export class DonationService {
  constructor(private http: HttpClient) {}

  getDonationByUser(e: string, bu: string, y: string): Observable<any> {
    return this.http.get(
      buildDonationUrl(GlobalVariable.EMPLOYEE_DONATION_EP, e, bu, y),
      {
        headers: this.getHeadersNA()
      }
    )
  }

  private getHeadersNA() {
    // I included these headers because otherwise FireFox
    // will request text/html instead of application/json
    const headers = new HttpHeaders()
    headers.set('Accept', 'application/json')
    return headers
  }
}

function buildDonationUrl(
  type: string,
  e: string,
  bu: string,
  y: string
): string {
  let finalUrl = GlobalVariable.BASE_API_URL
  finalUrl += type
  finalUrl += '?e=' + e
  finalUrl += '&bu=' + bu
  finalUrl += '&y=' + y
  return finalUrl
}
