import { Injectable } from "@angular/core"
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http"
import { Observable } from "rxjs"
import { map, filter, catchError, mergeMap } from "rxjs/operators"
import { GlobalVariable } from "../config/global"
import { User } from "../model/User"

@Injectable({
  providedIn: "root"
})
export class UserService {
  constructor(private http: HttpClient) {}

  getValidateUser(email: string, password: string): Observable<any> {
    return this.http.get(
      buildValidateUsersUrl(GlobalVariable.USER_VALIDATE_EP, email, password),
      {
        headers: this.getHeadersNA()
      }
    )
  }

  getNumberOfRoles(id: string): Observable<any> {
    return this.http.get(
      buildUserUrl(GlobalVariable.USER_NUMBEROFROLES_EP, id),
      {
        headers: this.getHeadersNA()
      }
    )
  }

  getRoles(id: string): Observable<any> {
    return this.http.get(buildUserUrl(GlobalVariable.USER_ROLES_EP, id), {
      headers: this.getHeadersNA()
    })
  }

  getUser(id: string): Observable<any> {
    return this.http.get(buildUserUrl(GlobalVariable.USER_READONE_EP, id), {
      headers: this.getHeadersNA()
    })
  }

  getPasswordReset(email: string, datetime: string): Observable<any> {
    return this.http.get(
      buildPasswordResetUrl(
        GlobalVariable.USER_PASSWORDRESET_EP,
        email,
        datetime
      ),
      {
        headers: this.getHeadersNA()
      }
    )
  }

  putUpdateProfile(submission: any): Observable<any> {
    return this.http.post(
      buildProfileUpdateUrl(GlobalVariable.USER_UPDATE_EP),
      submission,
      {
        headers: this.getHeadersNA()
      }
    )
  }

  private getHeadersNA() {
    // I included these headers because otherwise FireFox
    // will request text/html instead of application/json
    const headers = new HttpHeaders()
    headers.set("Accept", "application/json")
    return headers
  }
}

function buildValidateUsersUrl(type: string, e: string, p: string): string {
  let finalUrl = GlobalVariable.BASE_API_URL
  finalUrl += type
  finalUrl += "?e=" + e
  finalUrl += "&p=" + encodeURIComponent(window.btoa(p))
  return finalUrl
}

function buildPasswordResetUrl(
  type: string,
  email: string,
  datetime: string
): string {
  let finalUrl = GlobalVariable.BASE_API_URL
  finalUrl += type
  finalUrl += "?e=" + email
  finalUrl += "&datetime=" + datetime
  return finalUrl
}

function buildUserUrl(type: string, id: string): string {
  let finalUrl = GlobalVariable.BASE_API_URL
  finalUrl += type
  finalUrl += "?id=" + id
  return finalUrl
}

function buildProfileUpdateUrl(type: string): string {
  let finalUrl = GlobalVariable.BASE_API_URL
  finalUrl += type
  return finalUrl
}
