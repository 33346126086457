import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable } from 'rxjs'
import { GlobalVariable } from '../config/global'

@Injectable({
  providedIn: 'root'
})
export class NewService {
  constructor(private http: HttpClient) {}

  getNews(bu: string): Observable<any> {
    return this.http.get(
      buildNewsUrl(GlobalVariable.NEWS_BYBUSINESSUNIT_EP, bu),
      {
        headers: this.getHeadersNA()
      }
    )
  }

  private getHeadersNA() {
    // I included these headers because otherwise FireFox
    // will request text/html instead of application/json
    const headers = new HttpHeaders()
    headers.set('Accept', 'application/json')
    return headers
  }
}

function buildNewsUrl(type: string, bu: string): string {
  let finalUrl = GlobalVariable.BASE_API_URL
  finalUrl += type
  finalUrl += '?bu=' + bu
  return finalUrl
}
