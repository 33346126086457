import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable } from 'rxjs'
import { GlobalVariable } from '../config/global'

@Injectable({
  providedIn: 'root'
})
export class AccesslogService {
  constructor(private http: HttpClient) {}

  postAccessLog(submission: any): Observable<any> {
    return this.http.post(
      buildPOSTAccessLogUrl(GlobalVariable.ACCESSLOG_CREATE_EP),
      submission,
      {
        headers: this.getHeadersNA()
      }
    )
  }

  private getHeadersNA() {
    // I included these headers because otherwise FireFox
    // will request text/html instead of application/json
    const headers = new HttpHeaders()
    headers.set('Accept', 'application/json')
    headers.set(
      'Access-Control-Allow-Methods',
      'GET, POST, DELETE, PUT, OPTIONS'
    )
    headers.set('Access-Control-Allow-Origin', '*')
    return headers
  }
}

function buildPOSTAccessLogUrl(type: string): string {
  let finalUrl = GlobalVariable.BASE_API_URL
  finalUrl += type
  return finalUrl
}
