import { Component, OnInit } from "@angular/core"
import * as SecureLS from "secure-ls"
import { EmployeeService } from "src/app/service/employee.service"
import { Employee } from "src/app/model/employee"

@Component({
  selector: "app-sidenav",
  templateUrl: "./sidenav.component.html",
  styleUrls: ["./sidenav.component.scss"]
})
export class SidenavComponent implements OnInit {
  ls: SecureLS
  // employee information
  employee: Employee
  constructor(private employeeService: EmployeeService) {}

  ngOnInit() {
    this.ls = new SecureLS({ encodingType: "aes" })
    this.getEmployeeData()
  }
  /** function to get the current logged in user, call the function to get projects or register the API Token */
  getEmployeeData() {
    this.employee = <Employee>{
      id: ""
    }
    const userid = this.ls.get("uid")
    this.employeeService.getEmployee(userid).subscribe(
      p => {
        this.employee = p.results !== undefined ? p.results[0] : null
      },
      e => console.log(e),
      () => {}
    )
  }
}
