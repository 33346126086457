import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import * as SecureLS from "secure-ls";
import { HttpClient } from "@angular/common/http";
import {
  MatSnackBar,
  MatDatepicker,
  MatDatepickerInputEvent,
  MatDialog,
} from "@angular/material";
import { FormControl, Validators } from "@angular/forms";
import { MediaObserver, MediaChange } from "@angular/flex-layout";
import { Subscription } from "rxjs";
import * as momento from "moment";
import * as colombia from "colombia-holidays";
import { GlobalVariable } from "src/app/config/global";
import { User } from "src/app/model/user";
import { Employee } from "src/app/model/employee";
import { EmployeeService } from "src/app/service/employee.service";
import { BusinessUnit } from "src/app/model/businessUnit";
import { BusinessunitService } from "src/app/service/businessunit.service";
import { VacationService } from "src/app/service/vacation.service";
import { RequestService } from "src/app/service/request.service";
import { ConfirmationDialogComponent } from "../confirmation-dialog/confirmation-dialog.component";
declare var moment: any;

@Component({
  selector: "app-vacationrequest",
  templateUrl: "./vacationrequest.component.html",
  styleUrls: ["./vacationrequest.component.scss"],
})
export class VacationrequestComponent implements OnInit {
  AppName = "";
  currentScreenWidth: string = "";
  flexMediaWatcher: Subscription;
  sidenavWidth = 4.2;
  ls: SecureLS;
  // user information
  user: User;
  isLoading = false;
  errorMessage = "";
  // employee information
  employee: Employee;
  employees: Employee[];
  // Business Unit information
  selectBusinessUnit: FormControl;
  businessUnits: BusinessUnit[] = [];
  selectedBusinessUnit = <BusinessUnit>{
    id: "",
  };
  emergencyName;
  emergencyPhone;
  description;
  // date picker
  date: FormControl;
  dateEnd: FormControl;
  dateReturning: FormControl;
  endDateError = false;
  returningDateError = false;
  myFilter = (d: Date): boolean => {
    const day = d.getDay();
    // Prevent Saturday and Sunday from being selected.
    return day !== 0 && day !== 6;
  };
  // vacation information
  availableDays: number = 0;
  requestedDays: number = 0;
  remainingDays: number = 0;
  //requests
  requests = [];
  requestsFilter = [];
  inProcessRequests = [];
  approvedRequests = [];
  rejectedRequests = [];
  humanResources;

  constructor(
    private router: Router,
    private employeeService: EmployeeService,
    private businessunitService: BusinessunitService,
    private vacationService: VacationService,
    private requestService: RequestService,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    private http: HttpClient,
    private mediaObserver: MediaObserver
  ) {
    this.flexMediaWatcher = mediaObserver.media$.subscribe(
      (change: MediaChange) => {
        if (change.mqAlias !== this.currentScreenWidth) {
          this.currentScreenWidth = change.mqAlias;
        }
      }
    );
  }

  ngOnInit() {
    this.AppName = GlobalVariable.APP_NAME;
    this.ls = new SecureLS({ encodingType: "aes" });
    if (!this.validateSession()) {
      this.router.navigate(["/login"]);
      return;
    }
    this.initForms();
    this.getEmployeeData();
  }

  ngOnDestroy(): void {
    this.flexMediaWatcher.unsubscribe();
  }

  increase() {
    this.sidenavWidth = 16;
  }
  decrease() {
    this.sidenavWidth = 4.2;
  }

  resetForm() {
    this.emergencyName = "";
    this.emergencyPhone = "";
    this.description = "";
  }

  /** validate a valid session exists*/
  validateSession(): boolean {
    if (localStorage.getItem("uid") === null) {
      return false;
    } else {
      return true;
    }
  }

  /** function to init the forms */
  initForms() {
    this.selectBusinessUnit = new FormControl("", [Validators.required]);
    this.date = new FormControl({ value: new Date(), disabled: false });
    this.dateEnd = new FormControl({ value: new Date(), disabled: false });
    this.dateReturning = new FormControl({ value: new Date(), disabled: true });
  }

  getErrorMessage(component: string) {
    let errorMessage = "";
    switch (component) {
      case "endDate":
        errorMessage = "La fecha final debe ser mayor a la inicial";
        break;
      case "returningDate":
        errorMessage = "La fecha de retorno debe ser mayor a la final";
        break;
    }
    return errorMessage;
  }

  /** function to get the current logged in user, call the function to get projects or register the API Token */
  getEmployeeData() {
    this.isLoading = true;
    this.employee = <Employee>{
      id: "",
    };
    const userid = this.ls.get("uid");
    this.employeeService.getEmployee(userid).subscribe(
      (p) => {
        this.employees = p.results !== undefined ? p.results : [];
        this.employee = p.results !== undefined ? p.results[0] : null;
      },
      (e) => this.launchMessage(e),
      () => {
        if (
          this.employee.boss_id.trim() == "" &&
          this.employee.boss_name.trim() == "" &&
          this.employee.boss_email.trim() == ""
        ) {
          this.businessunitService
            .getBusinessUnit(this.employee.bu_code)
            .subscribe(
              (p) =>
                (this.humanResources =
                  p.results !== undefined ? p.results[0] : ""),
              (e) => this.launchMessage(e),
              () => {}
            );
        }
        this.isLoading = false;
        if (this.employee) {
          this.getBusinessUnitByEmployee();
        }
      }
    );
  }

  getBusinessUnitByEmployee() {
    this.isLoading = true;
    this.employees.map((employee) => {
      let bu: BusinessUnit;
      this.businessunitService.getBusinessUnit(employee.bu_code).subscribe(
        (p) => (bu = p.results !== undefined ? p.results[0] : null),
        (e) => this.launchMessage(e),
        () => {
          this.isLoading = false;
          if (bu) {
            this.businessUnits.push(bu);
          }
          this.selectedBusinessUnit = this.businessUnits.find(
            (bu) => bu.id == this.employee.bu_code
          );
          if (this.selectedBusinessUnit) {
            //call the function to get vacations information
            this.getMyVacationDays();
            this.getRequest();
          }
        }
      );
    });
  }

  /** Event handler when business unit select component changes */
  buSelectionChange(event: { value: BusinessUnit }) {
    this.selectedBusinessUnit = event.value;
    this.employee = this.employees.find((e) => e.bu_code === event.value.id);
    //call the function to get vacations information
    this.getMyVacationDays();
    this.getRequest();
  }

  getMyVacationDays() {
    this.vacationService
      .getMyVacationsDays(this.employee.id, this.employee.bu_code)
      .subscribe(
        (p) => (this.availableDays = p),
        (e) => this.launchMessage(e),
        () => {
          this.isLoading = false;
        }
      );
  }

  /** Event handler when date picker component value changes */
  chosenStartDateHandler(event: MatDatepickerInputEvent<Date>) {
    this.dateEnd.setValue(event.value);
    this.dateReturning.setValue(event.value);
    this.endDateError = false;
    this.returningDateError = false;
    if (this.employee) {
      this.calculateRequestedDays();
    }
  }

  /** Event handler when date picker component value changes */
  chosenEndDateHandler(event: MatDatepickerInputEvent<Date>) {
    this.requestedDays = 0;
    const startDate = moment(this.date.value);
    const endDate = moment(event.value);
    const diff = endDate.diff(startDate, "days") + 1;
    if (diff <= 0) {
      this.endDateError = true;
    } else {
      this.endDateError = false;
      const oneDayAfter = moment(event.value)
        .add(this.calculateNextBusinessDay(), "d")
        .format();
      this.dateReturning.setValue(oneDayAfter);
      if (this.employee) {
        this.calculateRequestedDays();
      }
    }
  }

  /** Event handler when date picker component value changes */
  chosenReturningDateHandler(event: MatDatepickerInputEvent<Date>) {
    const endDate = moment(this.dateEnd.value);
    const returningDate = moment(event.value);
    const diff = returningDate.diff(endDate, "days") + 1;
    if (diff < 0) {
      this.returningDateError = true;
    } else {
      this.returningDateError = false;
      if (this.employee) {
        this.calculateRequestedDays();
      }
    }
  }

  calculateNextBusinessDay() {
    var stopIf = 1;
    var daysToAdd = 0;
    var diffBusinessDays = 0;
    const endDate = moment(this.dateEnd.value).format();
    const dateBusinessDays = this.getBusinessDays(endDate, endDate);
    if (dateBusinessDays == 1) {
      stopIf = 2;
    } else {
      stopIf = 1;
    }
    while (diffBusinessDays < stopIf) {
      daysToAdd++;
      var oneDayAfter = moment(endDate).add(daysToAdd, "d").format();
      diffBusinessDays = this.getBusinessDays(endDate, oneDayAfter);
    }
    return daysToAdd;
  }

  calculateRequestedDays() {
    const startDate = moment(this.date.value);
    const endDate = moment(this.dateEnd.value);
    const diffStartEnd = endDate.diff(startDate, "days") + 1;
    const diffBusinessDays = this.getBusinessDays(startDate, endDate);
    this.requestedDays = diffBusinessDays;
    this.remainingDays = this.availableDays - this.requestedDays;
  }

  getBusinessDays(startDate, endDate) {
    var startDateMoment = moment(startDate);
    var endDateMoment = moment(endDate);
    var holidaysFirstYear = colombia
      .getColombiaHolidaysByYear(startDateMoment.year())
      .map(({ holiday }) => holiday);
    var holidaysSecondYear = colombia
      .getColombiaHolidaysByYear(endDateMoment.year())
      .map(({ holiday }) => holiday);
    var twoYearHolidays = [...holidaysFirstYear, ...holidaysSecondYear];

    var days = moment().isoWeekdayCalc({
      rangeStart: startDateMoment,
      rangeEnd: endDateMoment,
      weekdays: [1, 2, 3, 4, 5],
      exclusions: twoYearHolidays,
    });
    return days;
  }

  createRequest() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "350px",
      data: "¿Estás seguro que quieres crear la solicitud?",
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        // DO SOMETHING
        const submission = this.buildJSONToPostRequest();
        this.requestService.postRequest(submission).subscribe(
          (p) => {
            if (p.message) {
              this.launchMessage("Se ha creado tu solicitud exitosamente");
            }
          },
          (e) => this.launchMessage(e),
          () => {
            this.isLoading = false;
            this.date.setValue(new Date());
            this.dateEnd.setValue(new Date());
            this.dateReturning.setValue(new Date());
            this.requestedDays = 0;
            this.remainingDays = 0;
            this.resetForm();
            this.getRequest();
          }
        );
      }
    });
  }

  /** this method builds the json using the data enter in the form and selected assets */
  buildJSONToPostRequest(): any {
    const now = new Date();
    const month = now.getMonth() + 1;
    const x = {
      id: "2",
      date:
        now.getFullYear() +
        "-" +
        month +
        "-" +
        now.getDate() +
        " " +
        now.getHours() +
        ":" +
        now.getMinutes() +
        ":" +
        now.getSeconds(),
      employee_id: this.employee.id,
      boss_id: this.employee.boss_id,
      start_date: this.date.value,
      end_date: this.dateEnd.value,
      entrance_date: this.dateReturning.value,
      days: this.requestedDays,
      type: "1",
      status: "1",
      observation: "",
      bu_code: this.employee.bu_code,
      contact_name: this.emergencyName,
      contact_phone: this.emergencyPhone,
      description: this.description,
    };
    return x;
  }

  getRequest() {
    this.requestService
      .getRequests(this.employee.id, this.employee.bu_code)
      .subscribe(
        (p) => {
          this.requests = p.results !== undefined ? p.results : [];
        },
        (e) => this.launchMessage(e),
        () => {
          this.isLoading = false;

          // Vacations filter
          this.requestsFilter = this.requests.filter((r) => r.type === "1");

          if (this.requestsFilter.length > 0) {
            momento.locale("es");
            this.requestsFilter.forEach((request) => {
              request.date = momento(request.date, "YYYY-MM-DD").format(
                "DD MMMM YYYY"
              );
              request.start_date = momento(
                request.start_date,
                "YYYY-MM-DD"
              ).format("DD MMMM YYYY");
              request.end_date = momento(request.end_date, "YYYY-MM-DD").format(
                "DD MMMM YYYY"
              );
              request.entrance_date = momento(
                request.entrance_date,
                "YYYY-MM-DD"
              ).format("DD MMMM YYYY");
            });
            this.inProcessRequests = this.requestsFilter.filter(
              (r) => r.status === "1" || r.status === "2"
            );
            this.approvedRequests = this.requestsFilter.filter(
              (r) => r.status === "5"
            );
            this.rejectedRequests = this.requestsFilter.filter(
              (r) => r.status === "3" || r.status === "4"
            );
          }
        }
      );
  }

  launchMessage(message: string) {
    this.errorMessage = "";
    this.isLoading = false;
    const action = "OK";
    this.snackBar.open(message, action, {
      duration: 4000,
    });
  }
}
