import { Component, OnInit } from "@angular/core"
import { Router } from "@angular/router"
import { FormGroup, FormBuilder, FormControl, Validators } from "@angular/forms"
import { MatSnackBar } from "@angular/material"
import { GlobalVariable } from "src/app/config/global"
import { UserService } from "src/app/service/user.service"

@Component({
  selector: "app-password-reset",
  templateUrl: "./password-reset.component.html",
  styleUrls: ["./password-reset.component.scss"]
})
export class PasswordResetComponent implements OnInit {
  isLoading = false
  errorMessage = ""
  passwordResetForm: FormGroup
  AppName = GlobalVariable.APP_NAME

  constructor(
    private router: Router,
    public snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.initForms()
  }

  initForms() {
    this.passwordResetForm = this.formBuilder.group({
      email: new FormControl("", [Validators.required, Validators.email])
    })
  }

  getErrorMessage(component: string) {
    let errorMessage = ""
    switch (component) {
      case "email":
        errorMessage = this.passwordResetForm.get("email").hasError("required")
          ? "campo requerido"
          : this.passwordResetForm.get("email").hasError("email")
          ? "ingresa un correo válido"
          : ""
        break
    }
    return errorMessage
  }

  recoverPassword(form: FormGroup) {
    this.isLoading = true
    const now = new Date()
    const month = now.getMonth() + 1
    const datetime =
      now.getFullYear() +
      "-" +
      month +
      "-" +
      now.getDate() +
      " " +
      now.getHours() +
      ":" +
      now.getMinutes() +
      ":" +
      now.getSeconds()
    let response = ""
    this.userService.getPasswordReset(form.value.email, datetime).subscribe(
      p => (response = p.message),
      e => this.launchMessage(e),
      () => {
        this.isLoading = false
        let message = ""
        if (response == "Email sent.") {
          message =
            "Se ha enviado un mensaje con instrucciones al correo electrónico. Si no ves el mensaje en la bandeja de entrada, verifica el correo no deseado."
        } else if (response == "No user.") {
          message = "El correo ingresado no está registrado en nuestro sistema."
        } else {
          message = "Ha surgido un error, por favor intenta nuevamente."
        }
        this.launchMessage(message)
      }
    )
  }

  /** Launch message of the snackBar component */
  launchMessage(message: string) {
    this.errorMessage = ""
    this.isLoading = false
    const action = "OK"
    this.snackBar.open(message, action, {
      duration: 10000
    })
  }
}
