import { Component, OnInit } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { BnNgIdleService } from "bn-ng-idle"

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit {
  title = "helpem-fe"
  constructor(private route: ActivatedRoute, private router: Router) {}
  ngOnInit() {
    if (localStorage.getItem("uid") === null) {
      this.router.navigate(["/login"])
    } else {
      this.router.navigate(["/home"])
    }
  }
}
