import { Component, OnInit } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import * as SecureLS from "secure-ls"
import { FormGroup, FormBuilder, FormControl, Validators } from "@angular/forms"
import { MediaObserver, MediaChange } from "@angular/flex-layout"
import { Subscription } from "rxjs"
import * as moment from "moment"
import { MatSnackBar } from "@angular/material"
import { GlobalVariable } from "src/app/config/global"
import { User } from "src/app/model/user"
import { UserService } from "src/app/service/user.service"
import { Employee } from "src/app/model/employee"
import { EmployeeService } from "src/app/service/employee.service"
import { BusinessUnit } from "src/app/model/businessUnit"
import { BusinessunitService } from "src/app/service/businessunit.service"
import { News } from "src/app/model/news"
import { NewService } from "src/app/service/new.service"
import { BnNgIdleService } from "bn-ng-idle"

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"]
})
export class HomeComponent implements OnInit {
  AppName = ""
  currentScreenWidth: string = ""
  flexMediaWatcher: Subscription
  sidenavWidth = 4.2
  ls: SecureLS
  // user information
  user: User
  isLoading = false
  errorMessage = ""
  // employee information
  employee: Employee
  employees: Employee[]
  // business units information
  selectBusinessUnit: FormControl
  businessUnits: BusinessUnit[] = []
  selectedBusinessUnit: BusinessUnit
  // news information
  dataNews: News[] = []

  constructor(
    private router: Router,
    private userService: UserService,
    private employeeService: EmployeeService,
    private businessunitService: BusinessunitService,
    private newsService: NewService,
    public snackBar: MatSnackBar,
    private mediaObserver: MediaObserver,
    private bnIdle: BnNgIdleService
  ) {
    this.flexMediaWatcher = mediaObserver.media$.subscribe(
      (change: MediaChange) => {
        if (change.mqAlias !== this.currentScreenWidth) {
          this.currentScreenWidth = change.mqAlias
        }
      }
    )
    this.bnIdle.startWatching(900).subscribe(res => {
      if (res) {
        window.localStorage.clear()
        this.router.navigate(["/login"])
      }
    })
  }

  ngOnInit() {
    this.AppName = GlobalVariable.APP_NAME
    this.ls = new SecureLS({ encodingType: "aes" })
    if (!this.validateSession()) {
      this.router.navigate(["/login"])
      return
    }
    this.getEmployeeData()
    this.initForms()
  }

  ngOnDestroy(): void {
    this.flexMediaWatcher.unsubscribe()
  }

  increase() {
    this.sidenavWidth = 16
  }
  decrease() {
    this.sidenavWidth = 4.2
  }

  validateSession(): boolean {
    if (localStorage.getItem("uid") === null) {
      return false
    } else {
      return true
    }
  }

  /** function to init the forms */
  initForms() {
    this.selectBusinessUnit = new FormControl("", [Validators.required])
  }

  /** function to get the current logged in user, call the function to get projects or register the API Token */
  getEmployeeData() {
    this.employee = <Employee>{
      id: ""
    }
    const userid = this.ls.get("uid")
    this.employeeService.getEmployee(userid).subscribe(
      p => {
        this.employees = p.results !== undefined ? p.results : null
        this.employee = p.results !== undefined ? p.results[0] : null
      },
      e => this.launchMessage(e),
      () => {
        if (this.employee) {
          this.getBusinessUnitByEmployee()
          this.getNews()
        }
      }
    )
  }

  getBusinessUnitByEmployee() {
    this.employees.map(employee => {
      let bu: BusinessUnit
      this.businessunitService.getBusinessUnit(employee.bu_code).subscribe(
        p => (bu = p.results !== undefined ? p.results[0] : null),
        e => this.launchMessage(e),
        () => {
          this.isLoading = false
          this.businessUnits.push(bu)
          this.selectedBusinessUnit = bu
        }
      )
    })
  }

  getNews() {
    moment.locale("es")
    this.isLoading = true
    this.dataNews = []
    this.newsService.getNews(this.employee.bu_code).subscribe(
      p => (this.dataNews = p.results !== undefined ? p.results : []),
      e => this.launchMessage(e),
      () => {
        this.isLoading = false
        this.dataNews = this.dataNews.map(news => {
          news.date = moment(news.date, "YYYY-MM-DD HH:mm").format(
            "DD [de] MMMM [de] YYYY HH:mm"
          )
          return news
        })
      }
    )
  }

  launchMessage(message: string) {
    this.errorMessage = ""
    this.isLoading = false
    const action = "OK"
    this.snackBar.open(message, action, {
      duration: 4000
    })
  }
}
