import { Injectable } from "@angular/core"
import { HttpClient, HttpHeaders } from "@angular/common/http"
import { Observable } from "rxjs"
import { GlobalVariable } from "../config/global"

@Injectable({
  providedIn: "root"
})
export class VacationService {
  constructor(private http: HttpClient) {}

  getMyVacationsDays(employee_id: string, bu_code: string): Observable<any> {
    return this.http.get(
      buildMyVacationDaysUrl(
        GlobalVariable.EMPLOYEE_VACATION_EP,
        employee_id,
        bu_code
      ),
      {
        headers: this.getHeadersNA()
      }
    )
  }

  private getHeadersNA() {
    // I included these headers because otherwise FireFox
    // will request text/html instead of application/json
    const headers = new HttpHeaders()
    headers.set("Accept", "application/json")
    return headers
  }
}

function buildMyVacationDaysUrl(
  type: string,
  employee_id: string,
  bu_code: string
): string {
  let finalUrl = GlobalVariable.BASE_API_URL
  finalUrl += type
  finalUrl += "?employee_id=" + employee_id
  finalUrl += "&bu_code=" + bu_code
  return finalUrl
}
