import { Component, OnInit } from "@angular/core"
import { Router } from "@angular/router"
import * as SecureLS from "secure-ls"
import { FormControl, Validators } from "@angular/forms"
import { MediaObserver, MediaChange } from "@angular/flex-layout"
import {
  MatSnackBar,
  MatTableDataSource,
  MatDatepicker
} from "@angular/material"
import { Subscription } from "rxjs"
import jsPDF from "jspdf"
import "jspdf-autotable"
import { GlobalVariable } from "src/app/config/global"
import { User } from "src/app/model/user"
import { Employee } from "src/app/model/employee"
import { EmployeeService } from "src/app/service/employee.service"
import { BusinessUnit } from "src/app/model/businessUnit"
import { BusinessunitService } from "src/app/service/businessunit.service"
import { EmployeeTransaction } from "src/app/model/employeeTransaction"
import { EmployeetransactionService } from "src/app/service/employeetransaction.service"

@Component({
  selector: "app-accountstatement",
  templateUrl: "./accountstatement.component.html",
  styleUrls: ["./accountstatement.component.scss"]
})
export class AccountstatementComponent implements OnInit {
  AppName = ""
  currentScreenWidth: string = ""
  flexMediaWatcher: Subscription
  sidenavWidth = 4.2
  ls: SecureLS
  // user information
  user: User
  isLoading = false
  errorMessage = ""
  // employee information
  employee: Employee
  employees: Employee[]
  // business units information
  selectBusinessUnit: FormControl
  businessUnits: BusinessUnit[] = []
  selectedBusinessUnit: BusinessUnit
  // date picker
  date: FormControl
  // employee transactions
  displayedColumns: string[] = [
    "date",
    "journal",
    "description",
    "amount",
    "balance"
  ]
  dataEmployeeTransactions: EmployeeTransaction[] = []
  employeeTransactionsDataSource = new MatTableDataSource(
    this.dataEmployeeTransactions
  )
  initialBalance: number = 0
  finalBalance: number = 0

  constructor(
    private router: Router,
    private employeeService: EmployeeService,
    private businessunitService: BusinessunitService,
    private employeetransactionService: EmployeetransactionService,
    public snackBar: MatSnackBar,
    private mediaObserver: MediaObserver
  ) {
    this.flexMediaWatcher = mediaObserver.media$.subscribe(
      (change: MediaChange) => {
        if (change.mqAlias !== this.currentScreenWidth) {
          this.currentScreenWidth = change.mqAlias
          this.setupTable()
        }
      }
    )
  }

  ngOnInit() {
    this.AppName = GlobalVariable.APP_NAME
    this.ls = new SecureLS({ encodingType: "aes" })
    if (!this.validateSession()) {
      this.router.navigate(["/login"])
      return
    }
    this.getEmployeeData()
    this.initForms()
  }

  ngOnDestroy(): void {
    this.flexMediaWatcher.unsubscribe()
  }

  increase() {
    this.sidenavWidth = 16
  }
  decrease() {
    this.sidenavWidth = 4.2
  }

  /** validate a valid session exists*/
  validateSession(): boolean {
    if (localStorage.getItem("uid") === null) {
      return false
    } else {
      return true
    }
  }

  setupTable() {
    if (this.currentScreenWidth === "xs") {
      // only display internalId on larger screens
      this.displayedColumns = ["date", "description", "amount"]
    } else {
      this.displayedColumns = [
        "date",
        "journal",
        "description",
        "amount",
        "balance"
      ]
    }
  }

  /** function to init the forms */
  initForms() {
    this.selectBusinessUnit = new FormControl("", [Validators.required])
    this.date = new FormControl({ value: new Date(), disabled: false })
  }

  /** function to get the current logged in user, call the function to get projects or register the API Token */
  getEmployeeData() {
    this.employee = <Employee>{
      id: ""
    }
    const userid = this.ls.get("uid")
    this.employeeService.getEmployee(userid).subscribe(
      p => {
        this.employees = p.results !== undefined ? p.results : null
        this.employee = p.results !== undefined ? p.results[0] : null
      },
      e => this.launchMessage(e),
      () => {
        if (this.employee) {
          this.getBusinessUnitByEmployee()
        }
      }
    )
  }

  getBusinessUnitByEmployee() {
    this.employees.map(employee => {
      let bu: BusinessUnit
      this.businessunitService.getBusinessUnit(employee.bu_code).subscribe(
        p => (bu = p.results !== undefined ? p.results[0] : null),
        e => this.launchMessage(e),
        () => {
          this.isLoading = false
          if (bu) {
            this.businessUnits.push(bu)
          }
          this.selectedBusinessUnit = this.businessUnits.find(
            bu => bu.id == this.employee.bu_code
          )
          if (this.selectedBusinessUnit) {
            this.getTransactionsByEmployee()
          }
        }
      )
    })
  }

  /** Event handler when business unit select component changes */
  buSelectionChange(event: { value: BusinessUnit }) {
    this.selectedBusinessUnit = event.value
    this.employee = this.employees.find(e => e.bu_code === event.value.id)
    this.getTransactionsByEmployee()
  }

  /** Event handler when date picker component value changes */
  chosenMonthHandler(normalizedMonth: Date, datepicker: MatDatepicker<Date>) {
    this.date.setValue(normalizedMonth)
    datepicker.close()
    if (this.selectedBusinessUnit) {
      this.getTransactionsByEmployee()
    }
  }

  /** function to format the period based on the selected month and year */
  setPeriod(): string {
    let period = ""
    const year = this.date.value.getFullYear()
    const month = this.date.value.getMonth() + 1
    const monthwzero = month < 10 ? "0" + month : month
    period = year + "0" + monthwzero
    return period
  }

  getTransactionsByEmployee() {
    this.isLoading = true
    let balance = 0
    const selectedPeriod = this.setPeriod()
    this.dataEmployeeTransactions = []
    this.employeetransactionService
      .getEmployeeTransactions(
        this.selectedBusinessUnit.id,
        this.employee.acnt_code
      )
      .subscribe(
        p =>
          (this.dataEmployeeTransactions =
            p.results !== undefined ? p.results : []),
        e => this.launchMessage(e),
        () => {
          this.isLoading = false
          if (this.dataEmployeeTransactions.length > 0) {
            this.getInitialBalance(this.dataEmployeeTransactions)
            balance = this.initialBalance
            this.dataEmployeeTransactions = this.dataEmployeeTransactions
              .filter(transaction => {
                return transaction.period === selectedPeriod
              })
              .map(transaction => {
                balance = +balance + +transaction.amount
                transaction.balance = balance
                transaction.trans_datetime = transaction.trans_datetime
                  .replace(/-/g, "/")
                  .substring(0, 10)
                return transaction
              })
            this.finalBalance =
              this.dataEmployeeTransactions.length > 0
                ? this.dataEmployeeTransactions[
                    this.dataEmployeeTransactions.length - 1
                  ].balance
                : 0
            this.employeeTransactionsDataSource = new MatTableDataSource(
              this.dataEmployeeTransactions
            )
          }
        }
      )
  }

  getInitialBalance(allTransactions: EmployeeTransaction[]) {
    this.initialBalance = 0
    const selectedPeriod = this.setPeriod()
    allTransactions
      .filter(transaction => {
        return parseInt(transaction.period) < parseInt(selectedPeriod)
      })
      .map(transaction => {
        this.initialBalance = +this.initialBalance + +transaction.amount
        return transaction
      })
  }

  getCommaSeparatedTwoDecimalsNumber(number) {
    if (number < 0 && number > -0.001) {
      number = Math.abs(number)
    }
    const fixedNumber = Number.parseFloat(number).toFixed(2)
    return String(fixedNumber).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }

  getReport() {
    var doc = new jsPDF("p", "mm", "a4")
    const result = this.dataEmployeeTransactions.map(transaction => {
      var d = new Date(transaction.trans_datetime)
      const year = d.getFullYear()
      const month = d.getMonth() + 1
      const monthwzero = month < 10 ? "0" + month : month
      const day = d.getDate()
      return [
        monthwzero + "/" + day + "/" + year,
        transaction.jrnal_no + "-" + transaction.jrnal_line,
        transaction.descriptn,
        transaction.d_c === "D"
          ? this.getCommaSeparatedTwoDecimalsNumber(
              Math.abs(transaction.amount)
            )
          : "",
        transaction.d_c === "C"
          ? this.getCommaSeparatedTwoDecimalsNumber(transaction.amount)
          : "",
        Math.round(transaction.balance * 100) / 100 < 0
          ? "(" +
            this.getCommaSeparatedTwoDecimalsNumber(transaction.balance) +
            ")"
          : this.getCommaSeparatedTwoDecimalsNumber(transaction.balance)
      ]
    })
    const numberTransactions = this.dataEmployeeTransactions.length
    const pageHeight =
      doc.internal.pageSize.height || doc.internal.pageSize.getHeight()
    const pageWidth =
      doc.internal.pageSize.width || doc.internal.pageSize.getWidth()
    const margin = 5
    const rowMargin = 7
    // From Javascript
    var img = new Image()
    img.src = "assets/icon_isolated_green_min.png"
    doc.addImage(img, "png", 20, 19, 21, 21)
    doc.setFontSize(8)
    doc.setFontType("bold")
    doc.text(
      this.selectedBusinessUnit.union_name.toUpperCase(),
      pageWidth / 2,
      4 * margin,
      "center"
    )
    doc.setFontType("normal")
    doc.text(
      this.selectedBusinessUnit.name.toUpperCase(),
      pageWidth / 2,
      5 * margin,
      "center"
    )
    doc.text(
      "NIT " + this.selectedBusinessUnit.national_code,
      pageWidth / 2,
      6 * margin,
      "center"
    )
    doc.text(
      this.selectedBusinessUnit.address.toUpperCase() +
        " " +
        this.selectedBusinessUnit.location.toUpperCase(),
      pageWidth / 2,
      7 * margin,
      "center"
    )
    doc.text(
      "TEL: " +
        this.selectedBusinessUnit.phone +
        " - FAX " +
        this.selectedBusinessUnit.telefax,
      pageWidth / 2,
      8 * margin,
      "center"
    )
    doc.setFontType("bold")
    doc.text("ESTADO DE CUENTA", pageWidth / 2, 9 * margin, "center")
    doc.text("EMPLEADO: " + this.employee.name, 20, 10 * margin, "left")
    doc.setTextColor(62, 131, 145)
    doc.text("Periodo:", pageWidth - 55, 10 * margin, "left")
    doc.setTextColor(0, 0, 0)
    doc.setFontType("normal")
    doc.text(this.setPeriod(), pageWidth - 33, 10 * margin, "left")
    doc.setLineDash([2, 1], 0)
    doc.line(15, 11 * margin, 195, 11 * margin)
    doc.setTextColor(62, 131, 145)
    doc.setFontType("bold")
    doc.text("Saldo inicial", pageWidth - 55, 12 * margin, "left")
    doc.setTextColor(0, 0, 0)
    doc.text(
      this.getCommaSeparatedTwoDecimalsNumber(this.initialBalance),
      pageWidth - 17,
      12 * margin,
      "right"
    )
    doc.setTextColor(62, 131, 145)
    doc.text("Saldo final", pageWidth - 55, 13 * margin, "left")
    doc.setTextColor(0, 0, 0)
    doc.text(
      this.getCommaSeparatedTwoDecimalsNumber(
        this.dataEmployeeTransactions[numberTransactions - 1].balance
      ),
      pageWidth - 17,
      13 * margin,
      "right"
    )
    doc.setFontType("normal")
    doc.setLineDash([2, 1], 0)
    doc.line(15, 14 * margin, 195, 14 * margin)
    doc.autoTable({
      headStyles: {
        fillColor: [62, 131, 145],
        fontSize: 8
      },
      styles: {
        fontSize: 7
      },
      startY: 15 * margin,
      head: [
        [
          "Fecha",
          "Diario",
          "Descripción",
          { content: "Débito", styles: { halign: "center" } },
          { content: "Crédito", styles: { halign: "center" } },
          { content: "Saldo", styles: { halign: "center" } }
        ]
      ],
      body: result,
      columnStyles: {
        3: { halign: "right" },
        4: { halign: "right" },
        5: { halign: "right" }
      }
    })
    const totalTransactions = this.dataEmployeeTransactions.length
    const marginAfterTable = totalTransactions * rowMargin
    doc.setTextColor(0, 0, 0)
    doc.setLineDash([2, 1], 0)
    doc.line(
      15,
      16 * margin + marginAfterTable,
      195,
      16 * margin + marginAfterTable
    )
    doc.setTextColor(62, 131, 145)
    doc.setFontType("bold")
    doc.setTextColor(62, 131, 145)
    doc.text(
      "Saldo final",
      pageWidth - 55,
      17 * margin + marginAfterTable,
      "left"
    )
    doc.setTextColor(0, 0, 0)
    doc.text(
      this.getCommaSeparatedTwoDecimalsNumber(
        this.dataEmployeeTransactions[numberTransactions - 1].balance
      ),
      pageWidth - 17,
      17 * margin + marginAfterTable,
      "right"
    )
    doc.setTextColor(0, 0, 0)
    doc.setFontType("normal")
    doc.setLineDash([2, 1], 0)
    doc.line(
      15,
      18 * margin + marginAfterTable,
      195,
      18 * margin + marginAfterTable
    )
    var totalPagesExpression = "{total_pages_count_string}"
    doc.putTotalPages(totalPagesExpression)
    doc.save("ESTADO_CUENTA_" + this.setPeriod() + ".pdf")
  }

  launchMessage(message: string) {
    this.errorMessage = ""
    this.isLoading = false
    const action = "OK"
    this.snackBar.open(message, action, {
      duration: 4000
    })
  }
}
