import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable } from 'rxjs'
import { GlobalVariable } from '../config/global'

@Injectable({
  providedIn: 'root'
})
export class EmployeetransactionService {
  constructor(private http: HttpClient) {}

  getEmployeeTransactions(bu: string, ac: string): Observable<any> {
    return this.http.get(
      buildEmployeeTransactionUrl(
        GlobalVariable.EMPLOYEE_TRANSACTION_BYUSER_EP,
        bu,
        ac
      ),
      {
        headers: this.getHeadersNA()
      }
    )
  }

  private getHeadersNA() {
    // I included these headers because otherwise FireFox
    // will request text/html instead of application/json
    const headers = new HttpHeaders()
    headers.set('Accept', 'application/json')
    return headers
  }
}

function buildEmployeeTransactionUrl(
  type: string,
  bu: string,
  ac: string
): string {
  let finalUrl = GlobalVariable.BASE_API_URL
  finalUrl += type
  finalUrl += '?bu=' + bu
  finalUrl += '&ac=' + ac
  return finalUrl
}
